"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileService = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _http = require("./http");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class FileService {
  static async getFiles() {
    try {
      const data = await _http.HttpService.get('v1', '/download_files');
      return data.map(parseFileData);
    } catch (err) {
      console.error('[FileService][getFiles]', err);
      throw err;
    }
  }
  static async getFile(id) {
    try {
      const data = await _http.HttpService.get('v1', `/download_files/${id}`, [], [], null, {
        responseType: 'blob'
      });
      return data;
    } catch (err) {
      console.error('[FileService][getFile]', err);
      throw err;
    }
  }
}
exports.FileService = FileService;
const types = {
  DASHBOARD_CHALLENGES: 'challenges',
  DASHBOARD_COMMUNITY_MEMBERS: 'communityMembers',
  DASHBOARD_COMPUTED_ITINERARIES: 'computedRoutes',
  DASHBOARD_CONTRIBS: 'contributions',
  DASHBOARD_FACILITIES: 'facilities',
  DASHBOARD_ISOCHRONE: 'isochrone',
  DASHBOARD_QUALITY_CONTROL: 'QA',
  DASHBOARD_REPORTS: 'reports',
  DASHBOARD_SESSIONS: 'sessions',
  DASHBOARD_USER_TRACES: 'userTraces',
  USER_RGPD: 'userData',
  VIRTUAL_COUNTERS: 'virtualCounters'
};
const statuses = {
  DEMAND: 'requested',
  PREPARE: 'prepared',
  DOWNLOAD: 'downloaded',
  FAIL: 'failure',
  EXPIRED: 'expired'
};
function parseFileData(_ref) {
  let {
    id,
    type,
    title,
    description,
    state,
    counter,
    created,
    creator
  } = _ref;
  return new _models.File(id, types[type], title, description, statuses[state], counter, (0, _moment.default)(created), creator);
}