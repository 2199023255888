"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.backendParentAdministrativeLevels = exports.backendAdministrativeLevels = exports.administrativeLevels = exports.CyclabilityZoneService = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _http = require("./http");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class CyclabilityZoneService {
  static async getZones(_ref) {
    let {
      ids,
      countryCode,
      name,
      ref,
      partnerCode,
      point,
      period,
      query,
      search,
      page,
      rowsPerPage,
      ...otherProps
    } = _ref;
    const queryParams = [{
      key: 'administrative_level',
      value: 'administrativeLevel' in otherProps ? otherProps.administrativeLevel : otherProps.administrativeLevels.join(',')
    }];
    if (countryCode) queryParams.push({
      key: 'country_code',
      value: countryCode
    });
    if (name) queryParams.push({
      key: 'name',
      value: name
    });
    if (ref) queryParams.push({
      key: 'reference',
      value: ref
    });
    if (partnerCode) queryParams.push({
      key: 'partner_code',
      value: partnerCode
    });
    if (point) queryParams.push({
      key: 'longitude',
      value: point.coordinates[0]
    }, {
      key: 'latitude',
      value: point.coordinates[1]
    });
    if (period) {
      queryParams.push({
        key: 'period',
        value: 'custom'
      }, {
        key: 'date_start',
        value: period.from.format('DD-MM-YYYY')
      }, {
        key: 'date_end',
        value: period.to.format('DD-MM-YYYY')
      });
    }
    if (query) queryParams.push({
      key: 'query',
      value: query
    });
    if (search) queryParams.push({
      key: 'search',
      value: search
    });
    if (rowsPerPage) queryParams.push({
      key: 'page_size',
      value: rowsPerPage
    });
    if (ids) {
      ids.forEach(id => queryParams.push({
        key: 'ids[]',
        value: id
      }));
    }
    try {
      const {
        count,
        results
      } = await _http.HttpService.get('v4', '/cyclability_zones', [...queryParams, {
        key: 'page',
        value: page || 1
      }]);
      const cyclabilityZones = results.reduce((res, data) => {
        const cyclabilityZone = parseCyclabilityZone(data);
        if (cyclabilityZone) res.push(cyclabilityZone);
        return res;
      }, []);
      if (rowsPerPage && count > 100) {
        const res = await Promise.all(new Array(Math.ceil((count - 100) / 100)).fill(null).map((_, index) => _http.HttpService.get('v4', '/cyclability_zones', [...queryParams, {
          key: 'page',
          value: index + 2
        }])));
        cyclabilityZones.push(...res.flatMap(_ref2 => {
          let {
            results: pageResults
          } = _ref2;
          return pageResults.reduce((res, data) => {
            const cyclabilityZone = parseCyclabilityZone(data);
            if (cyclabilityZone) res.push(cyclabilityZone);
            return res;
          }, []);
        }));
      }
      return {
        count,
        zones: cyclabilityZones
      };
    } catch (err) {
      console.error('[CyclabilityZoneService][getZones]', err);
      throw err;
    }
  }
  static async getZone(id, _ref3) {
    let {
      period,
      query
    } = _ref3;
    const queryParams = [];
    if (period) {
      queryParams.push({
        key: 'period',
        value: 'custom'
      }, {
        key: 'date_start',
        value: period.from.format('DD-MM-YYYY')
      }, {
        key: 'date_end',
        value: period.to.format('DD-MM-YYYY')
      });
    }
    if (query) queryParams.push({
      key: 'query',
      value: query
    });
    try {
      const data = await _http.HttpService.get('v4', `/cyclability_zones/${id}`, queryParams);
      const zone = parseCyclabilityZone(data);
      if (!zone) throw new Error('zone not found');
      return zone;
    } catch (err) {
      console.error('[CyclabilityZoneService][getZone]', err);
      throw err;
    }
  }
  static async getParentZone(zone) {
    let {
      query
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      const queryParams = [];
      if (query) queryParams.push({
        key: 'query',
        value: query
      });
      const data = await _http.HttpService.get('v4', `/cyclability_zones/${zone.id}/parents`, queryParams);
      if ('detail' in data) return null;
      const parentAdministrativeLevel = backendParentAdministrativeLevels[zone.administrativeLevel];
      const parentData = data.results.find(_ref4 => {
        let {
          administrative_level
        } = _ref4;
        return administrative_level === parentAdministrativeLevel;
      });
      const parentZone = parentData ? parseCyclabilityZone(parentData) : null;
      return parentZone;
    } catch (err) {
      console.error('[CyclabilityZoneService][getParentZone]', err);
      return null;
    }
  }
  static async getChildrenZones(parentZoneId, _ref5) {
    let {
      period,
      query,
      ...otherProps
    } = _ref5;
    try {
      const queryParams = [{
        key: 'administrative_level',
        value: 'administrativeLevel' in otherProps ? otherProps.administrativeLevel : ''
      }, {
        key: 'page_size',
        value: 100
      }];
      if (period) {
        queryParams.push({
          key: 'period',
          value: 'custom'
        }, {
          key: 'date_start',
          value: period.from.format('DD-MM-YYYY')
        }, {
          key: 'date_end',
          value: period.to.format('DD-MM-YYYY')
        });
      }
      if (query) queryParams.push({
        key: 'query',
        value: query
      });
      const {
        count,
        results
      } = await _http.HttpService.get('v4', `/cyclability_zones/${parentZoneId}/children`, [...queryParams, {
        key: 'page',
        value: 1
      }]);
      const zones = results.reduce((res, data) => {
        const cyclabilityZone = parseCyclabilityZone(data);
        if (cyclabilityZone) res.push(cyclabilityZone);
        return res;
      }, []);
      if (count > 100) {
        const res = await Promise.all(new Array(Math.ceil((count - 100) / 100)).fill(null).map((_, index) => _http.HttpService.get('v4', `/cyclability_zones/${parentZoneId}/children`, [...queryParams, {
          key: 'page',
          value: index + 2
        }])));
        zones.push(...res.flatMap(_ref6 => {
          let {
            results: pageResults
          } = _ref6;
          return pageResults.reduce((res, data) => {
            const cyclabilityZone = parseCyclabilityZone(data);
            if (cyclabilityZone) res.push(cyclabilityZone);
            return res;
          }, []);
        }));
      }
      return zones;
    } catch (err) {
      console.error('[CyclabilityZoneService][getChildrenZones]', err);
      throw err;
    }
  }
  static async getEventChallenges(zone, eventId) {
    try {
      const data = await _http.HttpService.get('v2', `/cyclability_zones/${zone.id}/challenges`, [{
        key: 'event_id',
        value: eventId
      }]);
      return data.map(_ref7 => {
        let {
          challenge_id: challengeId,
          challenge_title: challengeTitle,
          group_id: groupId,
          group_title: groupTitle
        } = _ref7;
        return {
          challengeId,
          challengeTitle,
          groupId,
          groupTitle
        };
      });
    } catch (err) {
      console.error('[CyclabilityZoneService][getEventChallenges]', err);
      throw err;
    }
  }
  static async getPartnerContracts(zone) {
    try {
      const data = await _http.HttpService.get('v2', `/cyclability_zones/${zone.id}/partner_contracts`);
      return data.map(_ref8 => {
        let {
          partner_id: partnerId
        } = _ref8;
        return {
          partnerId
        };
      });
    } catch (err) {
      console.error('[CyclabilityZoneService][getPartnerContracts]', err);
      throw err;
    }
  }
}
exports.CyclabilityZoneService = CyclabilityZoneService;
const administrativeLevels = exports.administrativeLevels = {
  CITY: 'city',
  COUNTRY: 'country',
  DEPARTMENT: 'department',
  EPCI: 'epci',
  REGION: 'region'
};
const backendAdministrativeLevels = exports.backendAdministrativeLevels = {
  city: 'CITY',
  country: 'COUNTRY',
  department: 'DEPARTMENT',
  epci: 'EPCI',
  region: 'REGION'
};
const backendParentAdministrativeLevels = exports.backendParentAdministrativeLevels = {
  city: 'EPCI',
  epci: 'DEPARTMENT',
  department: 'REGION',
  region: 'COUNTRY',
  country: null,
  world: null
};
function parseCyclabilityZone(_ref9) {
  let {
    id,
    administrative_level,
    reference,
    country_code,
    name,
    geo_polygon: geometry,
    geo_polygon_simplified: geometrySimplified,
    stats
  } = _ref9;
  function parse(prop) {
    return prop || 0;
  }
  if (!administrative_level) throw new Error('no administrative_level');
  const distancesList = [];
  const slotsList = [];
  stats?.sort((a, b) => a.created.localeCompare(b.created)).map(_ref10 => {
    let {
      created,
      bnac_data: {
        mixedfacilities,
        lane,
        sharebusway,
        opposite,
        cycleway,
        greenway
      },
      bnsc_data: {
        gratuite: {
          gratuit,
          payant
        },
        capacite,
        couverture: {
          couvert
        },
        mobilier: {
          arceau,
          arceau_velo_grande_taille,
          rack_double_etage,
          ratelier
        },
        protection: {
          box_individuel_ferme,
          consigne_collective_fermee
        }
      }
    } = _ref10;
    const distances = {
      [_models.Facilities.Cycleways]: parse(cycleway),
      [_models.Facilities.Greenways]: parse(greenway),
      [_models.Facilities.Lanes]: parse(lane),
      [_models.Facilities.Opposites]: parse(opposite),
      [_models.Facilities.SharedBusways]: parse(sharebusway),
      [_models.Facilities.MixedFacilities]: parse(mixedfacilities)
    };
    const slots = {
      [_models.ParkingTypes.Free]: parse(gratuit),
      [_models.ParkingTypes.Private]: parse(payant),
      [_models.ParkingTypes.Sheltered]: parse(couvert),
      [_models.ParkingTypes.Secure]: parse(box_individuel_ferme) + parse(consigne_collective_fermee),
      [_models.ParkingTypes.Arch]: parse(arceau) + parse(arceau_velo_grande_taille),
      [_models.ParkingTypes.Rack]: parse(rack_double_etage) + parse(ratelier),
      [_models.ParkingTypes.Locked]: 0
    };
    distancesList.push(new _models.CyclabilityZoneStats((0, _moment.default)(created), {
      ...distances,
      all: Object.values(distances).reduce((res, distance) => res + distance, 0)
    }));
    slotsList.push(new _models.CyclabilityZoneBNSCStats((0, _moment.default)(created), {
      ...slots,
      all: parse(capacite)
    }));
  });
  return new _models.CyclabilityZone(id || -1, administrativeLevels[administrative_level], reference || null, country_code?.toLowerCase() || null, name || '', geometry || geometrySimplified || {
    type: 'MultiPolygon',
    coordinates: []
  }, distancesList, slotsList);
}