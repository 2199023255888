"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PoiService = void 0;
exports.parsePoi = parsePoi;
var _models = require("../models");
var _app = require("./app");
var _http = require("./http");
var _photo = require("./photo");
var _poiCategory = require("./poi-category");
var _poiLav = require("./poi-lav");
var _publicationStatus = require("./publication-status");
class PoiService {
  static getImportancesByZoom(zoom) {
    if (zoom >= 15) return [];
    if (zoom >= 14) return [1, 2, 3, 4, 5];
    if (zoom >= 13) return [1, 2, 3, 4];
    if (zoom >= 12) return [1, 2, 3];
    if (zoom >= 11) return [1, 2];
    if (zoom >= 10) return [1];
    return null;
  }
  static async getPois(categories, props) {
    const params = [];
    try {
      if ('ids' in props) {
        const {
          ids
        } = props;
        if (ids.length === 0) return [];
        params.push(...ids.map(id => ({
          key: 'ids[]',
          value: id
        })));
      } else if ('location' in props) {
        const {
          location: {
            lat,
            lng
          },
          radius
        } = props;
        params.push({
          key: 'close_to_location',
          value: true
        }, {
          key: 'latitude',
          value: lat
        }, {
          key: 'longitude',
          value: lng
        }, {
          key: 'radius',
          value: radius || 5000
        });
      } else if ('geometry' in props) {
        const {
          geometry,
          buffer
        } = props;
        params.push({
          key: 'geometry',
          value: geometry
        }, {
          key: 'buffer',
          value: buffer || 15000
        });
      } else {
        const {
          zoom,
          bounds: {
            north,
            east,
            south,
            west
          },
          selectedCategories
        } = props;
        const importances = PoiService.getImportancesByZoom(zoom);
        if (selectedCategories.length === 0 || !importances) return [];
        params.push({
          key: 'north',
          value: north
        }, {
          key: 'east',
          value: east
        }, {
          key: 'south',
          value: south
        }, {
          key: 'west',
          value: west
        }, ...importances.map(importance => ({
          key: 'importance_levels[]',
          value: importance
        })));
      }
      if ('selectedCategories' in props) {
        const {
          selectedCategories
        } = props;
        if (selectedCategories.length === 0) return [];
        params.push(...selectedCategories.map(code => ({
          key: 'category_codes[]',
          value: _poiCategory.poiBackendCategoryCodes[code] || -1
        })));
      }
      const res = await _http.HttpService.get('v1', '/pois', params);
      const categoriesMap = categories.reduce((res, _ref) => {
        let {
          id,
          code,
          children
        } = _ref;
        res[id] = [code];
        children.forEach(_ref2 => {
          let {
            id: childId,
            code: childCode
          } = _ref2;
          return res[childId] = [code, childCode];
        });
        return res;
      }, {});
      return res.map(data => parsePoi(data, categoriesMap, _app.AppService.environment.backendUrl));
    } catch (err) {
      console.error('[PoiService][getPois]', err);
      throw err;
    }
  }
  static async getPoi(id) {
    try {
      const data = await _http.HttpService.get('v1', `/pois/${id}`);
      return parsePoi(data, {}, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[PoiService][getPoi]', err);
      throw err;
    }
  }
  static async getPartnerPois(widgetConfig, language) {
    try {
      if (widgetConfig.code === 'lav') {
        const res = await new Promise((resolve, reject) => {
          const request = new XMLHttpRequest();
          const indexes = {
            fr: 'lav-geovelo-fr',
            en: 'lav-geovelo-en'
          };
          request.open('POST', `${_app.AppService.environment.lav.backendUrl}/indexes/${indexes[language] || 'lav-geovelo-fr'}/search`, true);
          request.responseType = 'json';
          request.setRequestHeader('Authorization', _app.AppService.environment.lav.apiKey);
          request.setRequestHeader('Content-Type', 'application/json');
          request.onload = () => {
            const {
              status,
              response
            } = request;
            if (status >= 200 && status < 300) {
              resolve(response);
            } else {
              reject(response);
            }
          };
          request.onerror = () => reject();
          request.send(JSON.stringify({
            limit: 20000
          }));
        });
        return res.hits.reduce((res, data) => {
          const poi = (0, _poiLav.parseLAVPoi)(data);
          if (poi) res.push(poi);
          return res;
        }, []);
      }
      return null;
    } catch (err) {
      console.error('[PoiService][getPartnerPois]', err);
      throw err;
    }
  }
}
exports.PoiService = PoiService;
function parsePoi(_ref3, categoriesMap, backendUrl) {
  let {
    id,
    title,
    description,
    geo_point,
    address,
    city,
    photos,
    web,
    email,
    phone,
    url_details,
    categories: categoryIds,
    importance,
    is_imported,
    publication_status
  } = _ref3;
  return new _models.Poi(id, title, description, new _models.Place(undefined, geo_point, address ? {
    primaryText: address,
    secondaryText: city || undefined
  } : undefined), photos.map(_ref4 => {
    let {
      title: photoTitle,
      ...data
    } = _ref4;
    return (0, _photo.parsePhoto)({
      title: photoTitle || title,
      ...data
    }, backendUrl);
  }), web, email, phone, url_details && url_details.match(/^\/(http|https|www)/) ? url_details : null, categoryIds.flatMap(id => categoriesMap[id]).filter(code => Boolean(code)), importance, false, is_imported, _publicationStatus.publicationStatuses[publication_status], 'geovelo');
}