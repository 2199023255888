"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AreaService = void 0;
var _models = require("../models");
var _http = require("./http");
class AreaService {
  static async createArea(_ref) {
    let {
      partnerTitle,
      cyclabilityZone
    } = _ref;
    try {
      const {
        name,
        geometry
      } = cyclabilityZone;
      if (!geometry) throw new Error('cyclability zone has no geometry');
      const {
        id
      } = await _http.HttpService.post('v2', `/areas`, [{
        key: 'buffer',
        value: 100
      }], [], JSON.stringify({
        title: `${partnerTitle} - ${name}`,
        label: `${partnerTitle} - ${name} (CI)`,
        geo_polygon: geometry
      }));
      return id;
    } catch (err) {
      console.error('[AreaService][createArea]', err);
      throw err;
    }
  }
  static async getArea(id, _ref2) {
    let {
      query
    } = _ref2;
    try {
      const data = await _http.HttpService.get('v2', `/areas/${id}`, [{
        key: 'query',
        value: query
      }]);
      return parseArea(data);
    } catch (err) {
      console.error('[AreaService][getArea]', err);
      throw err;
    }
  }
}
exports.AreaService = AreaService;
function parseArea(_ref3) {
  let {
    id,
    geo_polygon
  } = _ref3;
  return new _models.Area(id || 0, geo_polygon || undefined);
}