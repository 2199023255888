"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReportSourceService = void 0;
var _reportSource = require("../models/report-source");
var _http = require("./http");
class ReportSourceService {
  static async getReportSources() {
    try {
      const data = await _http.HttpService.get('v3', '/report_sources');
      const reportSources = data.reduce((res, typeData) => {
        const type = parseReportSource(typeData);
        if (type) res.push(type);
        return res;
      }, []);
      return reportSources;
    } catch (err) {
      console.error('[ReportSourceService][getReportSources]', err);
      return [];
    }
  }
}
exports.ReportSourceService = ReportSourceService;
function parseReportSource(_ref) {
  let {
    id,
    code,
    url,
    title
  } = _ref;
  return new _reportSource.ReportSource(id, code, url, title);
}