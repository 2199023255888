"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RideTraceService = void 0;
exports.parseCondensedRideTrace = parseCondensedRideTrace;
exports.parseRideTrace = parseRideTrace;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _http = require("./http");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class RideTraceService {
  static async addRideTrace(props) {
    try {
      const formData = new FormData();
      Object.keys(props).forEach(key => {
        const value = props[key];
        if (value !== undefined) {
          if (typeof value === 'number') formData.append(key, `${value}`);else formData.append(key, value);
        }
      });
      const data = await _http.HttpService.post('v1', '/ride_traces', [], [], formData);
      const trace = parseRideTrace(data);
      if (!trace) throw new Error('trace has no geometry');
      return trace;
    } catch (err) {
      console.error('[RideTraceService][addRideTrace]', err);
      throw err;
    }
  }
  static async getRideTraces() {
    try {
      const data = await _http.HttpService.get('v1', '/ride_traces', [{
        key: 'condensed',
        value: 'true'
      }]);
      return data.map(parseCondensedRideTrace);
    } catch (err) {
      console.error('[RideTraceService][getRideTraces]', err);
      throw err;
    }
  }
  static async getRideTrace(id) {
    try {
      const data = await _http.HttpService.get('v1', `/ride_traces/${id}`);
      return parseRideTrace(data);
    } catch (err) {
      console.error('[RideTraceService][getRideTrace]', err);
      return null;
    }
  }
}
exports.RideTraceService = RideTraceService;
function parseCondensedRideTrace(_ref) {
  let {
    id,
    title,
    description,
    creator,
    created
  } = _ref;
  return new _models.CondensedRideTrace(id, title || '', description, creator, (0, _moment.default)(created));
}
function parseRideTrace(_ref2) {
  let {
    id,
    title,
    description,
    geometry,
    osm_relation_id,
    reference_id,
    creator,
    created
  } = _ref2;
  if (geometry === null) return null;
  return new _models.RideTrace(id, title || '', description, geometry, osm_relation_id, reference_id, creator, (0, _moment.default)(created));
}