"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CyclingPotentialService = void 0;
var _services = require("../services");
class CyclingPotentialService {
  static async getCyclingPotential(_ref) {
    let {
      point: {
        coordinates: [lng, lat]
      }
    } = _ref;
    try {
      const {
        facility_distances: facilityDistances
      } = await _services.HttpService.get('v1', `/bicycle_potential`, [{
        key: 'latitude',
        value: lat
      }, {
        key: 'longitude',
        value: lng
      }]);
      return {
        facilityDistances
      };
    } catch (err) {
      console.error('[CyclingPotentialService][getCyclingPotential]', err);
      throw err;
    }
  }
}
exports.CyclingPotentialService = CyclingPotentialService;