"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reportTypesTitleKeys = exports.reportTypesCodes = exports.backendReportTypesCodes = exports.ReportTypeService = void 0;
var _reportType = require("../models/report-type");
var _http = require("./http");
class ReportTypeService {
  static async getReportTypes() {
    try {
      const data = await _http.HttpService.get('v2', '/report_types');
      const reportTypes = data.reduce((res, typeData) => {
        const type = parseReportType(typeData);
        if (type) res.push(type);
        return res;
      }, []);
      return reportTypes;
    } catch (err) {
      console.error('[ReportTypeService][getReportTypes]', err);
      return [];
    }
  }
}
exports.ReportTypeService = ReportTypeService;
const reportTypesCodes = exports.reportTypesCodes = {
  BIKE_FACILITY: 'bikeFacility',
  BIKE_PARKING: 'bikeParking',
  CARTOGRAPHIC_ISSUE: 'cartographicIssue',
  EXCLUSION_ZONE: 'exclusionZone',
  TO_DO_TO_SEE: 'toDoToSee',
  DANGEROUS: 'dangerous',
  ROADWORK: 'roadwork',
  ROAD_BLOCKED: 'roadBlocked',
  POTHOLE: 'pothole',
  SUPPORT: 'support'
};
const backendReportTypesCodes = exports.backendReportTypesCodes = {
  bikeFacility: 'BIKE_FACILITY',
  bikeParking: 'BIKE_PARKING',
  cartographicIssue: 'CARTOGRAPHIC_ISSUE',
  exclusionZone: 'EXCLUSION_ZONE',
  toDoToSee: 'TO_DO_TO_SEE',
  dangerous: 'DANGEROUS',
  roadwork: 'ROADWORK',
  roadBlocked: 'ROAD_BLOCKED',
  pothole: 'POTHOLE',
  support: 'SUPPORT'
};
const reportTypesTitleKeys = exports.reportTypesTitleKeys = {
  bikeFacility: 'commons.report_types.bike_facility',
  bikeParking: 'commons.report_types.bike_parking',
  cartographicIssue: 'commons.report_types.cartographic_issue',
  exclusionZone: 'commons.report_types.exclusion_zone',
  toDoToSee: 'commons.report_types.to_do_to_see',
  dangerous: 'commons.report_types.dangerous',
  roadwork: 'commons.report_types.roadwork',
  roadBlocked: 'commons.report_types.road_blocked',
  pothole: 'commons.report_types.pothole',
  support: 'commons.report_types.support'
};
function parseReportType(_ref) {
  let {
    id,
    code: _code,
    is_bind_to_osm: isBindToOSM
  } = _ref;
  const code = reportTypesCodes[_code];
  if (!code) return null;
  return new _reportType.ReportType(id, code, isBindToOSM, reportTypesTitleKeys[code]);
}