"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GISService = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _app = require("./app");
var _http = require("./http");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class GISService {
  static async sendFile(_ref) {
    let {
      partnerCode,
      ...props
    } = _ref;
    try {
      const formData = new FormData();
      Object.keys(props).forEach(key => {
        const value = props[key];
        if (value) formData.append(key, value);
      });
      const data = await _http.HttpService.post('v1', '/sig_files', [], partnerCode ? [{
        key: 'partner',
        value: partnerCode
      }] : [], formData);
      return parseGisFileData(data);
    } catch (err) {
      console.error('[GISService][sendFile]', err);
      throw err;
    }
  }
  static async getFiles() {
    let {
      partnerCode
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    try {
      const data = await _http.HttpService.get('v1', '/sig_files', [], [], null, partnerCode ? {
        partner: partnerCode
      } : {});
      return data.map(parseGisFileData);
    } catch (err) {
      console.error('[GISService][getFiles]', err);
      throw err;
    }
  }
  static async getFileMessages(_ref2) {
    let {
      fileId,
      query
    } = _ref2;
    try {
      const res = await _http.HttpService.get('v1', `/sig_files/${fileId}/messages`, [{
        key: 'query',
        value: query
      }, {
        key: 'ordering',
        value: 'id'
      }]);
      return res.map(data => parseGisFileMessageData(data, _app.AppService.environment.backendUrl));
    } catch (err) {
      console.error('[GISService][getFileMessages]', err);
      throw err;
    }
  }
  static async sendMessage(_ref3) {
    let {
      fileId,
      message,
      document
    } = _ref3;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const formData = new FormData();
      if (document) formData.append('document', document);
      formData.append('data', JSON.stringify({
        message
      }));
      const data = await _http.HttpService.post('v1', `/sig_files/${fileId}/messages`, [], [], formData);
      return parseGisFileMessageData({
        ...data,
        creator: _user.UserService.currentUser,
        created: (0, _moment.default)().toISOString()
      }, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[GISService][sendMessage]', err);
      throw err;
    }
  }
}
exports.GISService = GISService;
const statuses = {
  TO_DO: 'toDo',
  DOING: 'doing',
  STAND_BY: 'standby',
  DONE: 'done'
};
function parseGisFileData(_ref4) {
  let {
    id,
    title,
    comment,
    document,
    status,
    created,
    creator,
    messages
  } = _ref4;
  return new _models.GISFile(id, title, comment, document, statuses[status], (0, _moment.default)(created), creator, messages || []);
}
function parseGisFileMessageData(_ref5, backendUrl) {
  let {
    created,
    creator,
    message,
    attachment
  } = _ref5;
  return new _models.GISFileMessage((0, _moment.default)(created), creator, message, attachment ? `${backendUrl}${attachment}` : null);
}