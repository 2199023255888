"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StripeService = void 0;
var _environment = require("../environment");
var _http = require("./http");
class StripeService {
  static async createCheckoutSession(_ref) {
    let {
      partnerCode,
      priceId,
      productId,
      customPrice,
      customPriceRecurrence
    } = _ref;
    try {
      const params = {};
      if (priceId) params.price_id = priceId;
      if (productId) params.product_id = productId;
      if (customPrice !== undefined) {
        params.price_value_in_cents = customPrice;
        if (customPriceRecurrence !== undefined) params.recurring_interval = customPriceRecurrence === 'annual' ? 'year' : 'month';
      }
      const {
        stripe_url
      } = await _http.HttpService.post('v1', `/billing/stripe/checkout_session`, [], [], JSON.stringify(params), {
        partner: partnerCode
      });
      return stripe_url;
    } catch (err) {
      console.error('[StripeService][createCheckoutSession]', err);
      throw new Error(err);
    }
  }
  static async createPortalSession(_ref2) {
    let {
      code: partner
    } = _ref2;
    try {
      const {
        stripe_url
      } = await _http.HttpService.post('v1', `/billing/stripe/portal_session`, [], [], null, {
        partner
      });
      return stripe_url;
    } catch (err) {
      console.error('[StripeService][createPortalSession]', err);
      throw new Error(err);
    }
  }
  static async getProducts(_ref3) {
    let {
      contractCodes
    } = _ref3;
    try {
      const products = await new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('GET', `https://api.stripe.com/v1/products?active=true`, true);
        request.responseType = 'json';
        request.setRequestHeader('Authorization', `Bearer ${_environment.defaultEnvironment.stripeApiKey}`);
        request.onload = () => {
          const {
            status,
            response
          } = request;
          if (status == 200) {
            resolve(response.data.map(_ref4 => {
              let {
                id,
                metadata,
                description,
                features
              } = _ref4;
              return {
                id,
                contractCode: metadata?.contract_code || '',
                description,
                descriptionLong: metadata?.description_long || '',
                features: features.map(_ref5 => {
                  let {
                    name
                  } = _ref5;
                  return name;
                })
              };
            }));
          } else {
            reject(response || new Error(`Error ${status}`));
          }
        };
        request.onerror = () => {
          console.error('[HTTP] Request Error');
          reject(request.response);
        };
        request.send(null);
      });
      return products.filter(_ref6 => {
        let {
          contractCode
        } = _ref6;
        return contractCodes.includes(contractCode);
      });
    } catch (err) {
      console.error('[StripeService][getProducts]', err);
      throw err;
    }
  }
  static async getPrices(_ref7) {
    let {
      productId
    } = _ref7;
    try {
      const prices = await new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('GET', `https://api.stripe.com/v1/prices?active=true&product=${productId}&currency=eur`, true);
        request.responseType = 'json';
        request.setRequestHeader('Authorization', `Bearer ${_environment.defaultEnvironment.stripeApiKey}`);
        request.onload = () => {
          const {
            status,
            response
          } = request;
          if (status == 200) {
            resolve(response.data.filter(_ref8 => {
              let {
                metadata,
                recurring
              } = _ref8;
              return metadata.company_workforce_count_limit && recurring;
            }).map(_ref9 => {
              let {
                id,
                metadata,
                recurring,
                unit_amount
              } = _ref9;
              return {
                id,
                companyMaxSize: parseInt(metadata.company_workforce_count_limit || ''),
                periodType: recurring?.interval || 'month',
                price: unit_amount / 100
              };
            }).filter(_ref10 => {
              let {
                companyMaxSize
              } = _ref10;
              return !Number.isNaN(companyMaxSize);
            }));
          } else {
            reject(response || new Error(`Error ${status}`));
          }
        };
        request.onerror = () => {
          console.error('[HTTP] Request Error');
          reject(request.response);
        };
        request.send(null);
      });
      return prices.filter(_ref11 => {
        let {
          companyMaxSize,
          periodType
        } = _ref11;
        return !isNaN(companyMaxSize) && companyMaxSize >= 0 && ['month', 'year'].includes(periodType);
      });
    } catch (err) {
      console.error('[StripeService][getPrices]', err);
      throw err;
    }
  }
}
exports.StripeService = StripeService;