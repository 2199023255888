"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SuddenBrakingService = void 0;
var _http = require("./http");
class SuddenBrakingService {
  static async getSuddenBrakings(_ref) {
    let {
      partner: {
        id
      },
      period,
      dayPeriod
    } = _ref;
    try {
      const result = await _http.HttpService.get('v1', `/stats/partner/${id}/hard_braking_clusters`, [{
        key: 'startDate',
        value: period.from.format('YYYY-MM-DD')
      }, {
        key: 'endDate',
        value: period.to.format('YYYY-MM-DD')
      }, {
        key: 'daysOfWeek',
        value: dayPeriod
      }]);
      return result;
    } catch (err) {
      console.error('[SuddenBrakingService][getSuddenBrakings]', err);
      throw new Error(err);
    }
  }
}
exports.SuddenBrakingService = SuddenBrakingService;