"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RelatedGPSAppsService = void 0;
var _http = require("./http");
var _user = require("./user");
class RelatedGPSAppsService {
  static async getGarminStatus() {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const {
        oauth_token_authorized: oauthTokenAuthorized,
        activity_api_authorized: activityApiAuthorized
      } = await _http.HttpService.get('v1', `/users/${_user.UserService.currentUser.id}/garmin_connect`, [], [], null, {
        expectedStatus: 200
      });
      return {
        activityApiAuthorized,
        oauthTokenAuthorized
      };
    } catch (err) {
      if (typeof err === 'object' && err && 'status' in err && err.status === 404) return null;
      console.error('[RelatedGPSAppsService][getGarminStatus]', err);
      throw err;
    }
  }
  static async startGarminConnection() {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const {
        garmin_url
      } = await _http.HttpService.post('v1', `/garmin_connect/start`);
      return garmin_url;
    } catch (err) {
      console.error('[RelatedGPSAppsService][startGarminConnection]', err);
      throw err;
    }
  }
  static async deleteGarminConnection() {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v1', `/users/${_user.UserService.currentUser.id}/garmin_connect`);
      return true;
    } catch (err) {
      console.error('[RelatedGPSAppsService][deleteGarminConnection]', err);
      throw err;
    }
  }
  static async getWahooStatus() {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const {
        authorized: activityApiAuthorized
      } = await _http.HttpService.get('v1', `/users/${_user.UserService.currentUser.id}/wahoo`, [], [], null, {
        expectedStatus: 200
      });
      return {
        activityApiAuthorized
      };
    } catch (err) {
      if (typeof err === 'object' && err && 'status' in err && err.status === 404) return null;
      console.error('[RelatedGPSAppsService][getWahooStatus]', err);
      throw err;
    }
  }
  static async createWahooConnection(_ref) {
    let {
      code
    } = _ref;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      await _http.HttpService.post('v1', `/users/${_user.UserService.currentUser.id}/wahoo`, [], [], JSON.stringify({
        code
      }));
      return true;
    } catch (err) {
      console.error('[RelatedGPSAppsService][createWahooConnection]', err);
      throw err;
    }
  }
  static async deleteWahooConnection() {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v1', `/users/${_user.UserService.currentUser.id}/wahoo`);
      return true;
    } catch (err) {
      console.error('[RelatedGPSAppsService][deleteWahooConnection]', err);
      throw err;
    }
  }
}
exports.RelatedGPSAppsService = RelatedGPSAppsService;