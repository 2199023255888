"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FMDParametersService = void 0;
exports.parseFMDParameters = parseFMDParameters;
var _models = require("../models");
var _http = require("./http");
class FMDParametersService {
  static async addConfiguration(partner, _ref) {
    let {
      type,
      compensationRate,
      maxCompensationPerYear,
      fixedFMDLevels,
      minDaysCountPerMonth,
      certificateValidationLimitDay,
      compensableDays,
      receiptionOfCertificatesEnabled,
      maxManuallyDeclaredJourneysRatio,
      annualPeriodStartDay,
      annualPeriodStartMonth,
      annualPeriodEndDay,
      annualPeriodEndMonth
    } = _ref;
    try {
      const body = {
        type,
        compensation_rate: compensationRate,
        max_compensation_per_year: maxCompensationPerYear,
        fixed_fmd_levels: fixedFMDLevels.map(_ref2 => {
          let {
            level,
            minDays: days_count_per_year_threshold,
            compensation
          } = _ref2;
          return {
            level,
            days_count_per_year_threshold,
            compensation
          };
        })
      };
      if (minDaysCountPerMonth !== undefined) body.min_days_count_per_month = minDaysCountPerMonth;
      if (certificateValidationLimitDay !== undefined) body.attestation_validation_limit_day = certificateValidationLimitDay;
      if (compensableDays) {
        body.is_friday_compensable = compensableDays.friday;
        body.is_monday_compensable = compensableDays.monday;
        body.is_saturday_compensable = compensableDays.saturday;
        body.is_sunday_compensable = compensableDays.sunday;
        body.is_thursday_compensable = compensableDays.thursday;
        body.is_tuesday_compensable = compensableDays.tuesday;
        body.is_wednesday_compensable = compensableDays.wednesday;
      }
      if (receiptionOfCertificatesEnabled !== undefined) body.enable_admin_attestation_email = receiptionOfCertificatesEnabled;
      if (maxManuallyDeclaredJourneysRatio !== undefined) body.authorized_manually_declared_journeys_ratio = maxManuallyDeclaredJourneysRatio;
      if (annualPeriodStartDay !== undefined) {
        body.annual_period_start_day = annualPeriodStartDay;
        body.annual_period_start_month = annualPeriodStartMonth;
        body.annual_period_end_day = annualPeriodEndDay;
        body.annual_period_end_month = annualPeriodEndMonth;
      }
      const data = await _http.HttpService.post('v2', `/partners/${partner.id}/fmd_parameters`, [], [], JSON.stringify(body), {
        partner: partner.code
      });
      return parseFMDParameters(data);
    } catch (err) {
      console.error('[FMDParametersService][addConfiguration]', err);
      throw err;
    }
  }
  static async getConfigurations(partner) {
    try {
      const results = await _http.HttpService.get('v2', `/partners/${partner.id}/fmd_parameters`, [], [], null, {
        partner: partner.code
      });
      const configurations = results.map(data => parseFMDParameters(data));
      return configurations;
    } catch (err) {
      console.error('[FMDParametersService][getConfigurations]', err);
      throw Array.isArray(err) ? new Error(err[0]) : err;
    }
  }
  static async getConfiguration(partnerId, id) {
    try {
      const data = await _http.HttpService.get('v2', `/partners/${partnerId}/fmd_parameters/${id}`);
      return parseFMDParameters(data);
    } catch (err) {
      console.error('[FMDParametersService][getConfiguration]', err);
      throw err;
    }
  }
  static async getConfigurationByCode(code) {
    try {
      const data = await _http.HttpService.get('v2', `/partners_fmd_parameters/${code}`);
      return parseFMDParameters(data, code);
    } catch (err) {
      console.error('[FMDParametersService][getConfigurationByCode]', err);
      throw err;
    }
  }
  static async updateConfiguration(partner, id, _ref3) {
    let {
      type,
      compensationRate,
      maxCompensationPerYear,
      fixedFMDLevels,
      minDaysCountPerMonth,
      certificateValidationLimitDay,
      compensableDays,
      receiptionOfCertificatesEnabled,
      maxManuallyDeclaredJourneysRatio,
      annualPeriodStartDay,
      annualPeriodStartMonth,
      annualPeriodEndDay,
      annualPeriodEndMonth
    } = _ref3;
    try {
      const body = {};
      if (type) body.type = type;
      if (compensationRate !== undefined) body.compensation_rate = compensationRate;
      if (maxCompensationPerYear !== undefined) body.max_compensation_per_year = maxCompensationPerYear;
      if (fixedFMDLevels !== undefined) {
        body.fixed_fmd_levels = fixedFMDLevels.map(_ref4 => {
          let {
            level,
            minDays: days_count_per_year_threshold,
            compensation
          } = _ref4;
          return {
            level,
            days_count_per_year_threshold,
            compensation
          };
        });
      }
      if (minDaysCountPerMonth !== undefined) body.min_days_count_per_month = minDaysCountPerMonth;
      if (certificateValidationLimitDay !== undefined) body.attestation_validation_limit_day = certificateValidationLimitDay;
      if (compensableDays) {
        body.is_friday_compensable = compensableDays.friday;
        body.is_monday_compensable = compensableDays.monday;
        body.is_saturday_compensable = compensableDays.saturday;
        body.is_sunday_compensable = compensableDays.sunday;
        body.is_thursday_compensable = compensableDays.thursday;
        body.is_tuesday_compensable = compensableDays.tuesday;
        body.is_wednesday_compensable = compensableDays.wednesday;
      }
      if (receiptionOfCertificatesEnabled !== undefined) body.enable_admin_attestation_email = receiptionOfCertificatesEnabled;
      if (maxManuallyDeclaredJourneysRatio !== undefined) body.authorized_manually_declared_journeys_ratio = maxManuallyDeclaredJourneysRatio;
      if (annualPeriodStartDay !== undefined) {
        body.annual_period_start_day = annualPeriodStartDay;
        body.annual_period_start_month = annualPeriodStartMonth;
        body.annual_period_end_day = annualPeriodEndDay;
        body.annual_period_end_month = annualPeriodEndMonth;
      }
      const data = await _http.HttpService.put('v2', `/partners/${partner.id}/fmd_parameters/${id}`, [], [], JSON.stringify(body), {
        partner: partner.code
      });
      return parseFMDParameters(data);
    } catch (err) {
      console.error('[FMDParametersService][updateConfiguration]', err);
      throw err;
    }
  }
  static async deleteConfiguration(partner, id) {
    try {
      await _http.HttpService.delete('v2', `/partners/${partner.id}/fmd_parameters/${id}`, [], [], null, {
        partner: partner.code
      });
      return true;
    } catch (err) {
      console.error('[FMDParametersService][deleteConfiguration]', err);
      throw err;
    }
  }
}
exports.FMDParametersService = FMDParametersService;
function parseFMDParameters(_ref5, configCode) {
  let {
    id,
    partner,
    code,
    type,
    compensation_rate,
    max_compensation_per_year,
    fixed_fmd_levels,
    min_days_count_per_month,
    attestation_validation_limit_day,
    is_friday_compensable,
    is_monday_compensable,
    is_saturday_compensable,
    is_sunday_compensable,
    is_thursday_compensable,
    is_tuesday_compensable,
    is_wednesday_compensable,
    enable_admin_attestation_email,
    authorized_manually_declared_journeys_ratio,
    annual_period_start_day: annualPeriodStartDay,
    annual_period_start_month: annualPeriodStartMonth,
    annual_period_end_day: annualPeriodEndDay,
    annual_period_end_month: annualPeriodEndMonth
  } = _ref5;
  return new _models.FMDConfig(id, !partner || typeof partner === 'number' ? {} : {
    code: configCode,
    title: partner.title,
    icon: partner.icon
  }, code, type === 'DAILY' ? 'daily' : type === 'DISTANCE' ? 'distance' : 'fixed', compensation_rate, type === 'FIXED' ? fixed_fmd_levels && fixed_fmd_levels.length > 0 ? Math.max(...fixed_fmd_levels.map(_ref6 => {
    let {
      compensation
    } = _ref6;
    return compensation;
  })) : undefined : max_compensation_per_year, type === 'FIXED' ? (fixed_fmd_levels || []).map(_ref7 => {
    let {
      level,
      days_count_per_year_threshold: minDays,
      compensation
    } = _ref7;
    return {
      level,
      minDays,
      compensation
    };
  }) : [], min_days_count_per_month, attestation_validation_limit_day, {
    monday: is_monday_compensable || false,
    tuesday: is_tuesday_compensable || false,
    wednesday: is_wednesday_compensable || false,
    thursday: is_thursday_compensable || false,
    friday: is_friday_compensable || false,
    saturday: is_saturday_compensable || false,
    sunday: is_sunday_compensable || false
  }, enable_admin_attestation_email, authorized_manually_declared_journeys_ratio || authorized_manually_declared_journeys_ratio === 0 ? authorized_manually_declared_journeys_ratio : null, annualPeriodStartDay, annualPeriodStartMonth, annualPeriodEndDay, annualPeriodEndMonth);
}