"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.priorities = exports.BikeRouteService = void 0;
var _models = require("../models");
var _app = require("./app");
var _http = require("./http");
var _photo = require("./photo");
var _publicationStatus = require("./publication-status");
var _ride = require("./ride");
class BikeRouteService {
  static async addBikeRoute(_ref) {
    let {
      title
    } = _ref;
    try {
      const data = await _http.HttpService.post('v1', `/ridesets`, [], [], JSON.stringify({
        title
      }));
      return parseBikeRoute(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[BikeRouteService][addBikeRoute]', err);
      throw err;
    }
  }
  static async addBikeRoutePhoto(bikeRouteId, _ref2) {
    let {
      title,
      description,
      copyright,
      file
    } = _ref2;
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('copyright', copyright);
      formData.append('photo', file);
      const data = await _http.HttpService.post('v1', `/ridesets/${bikeRouteId}/photos`, [], [], formData);
      return (0, _photo.parsePhoto)(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[BikeRouteService][addBikeRoutePhoto]', err);
      throw err;
    }
  }
  static async addBikeRouteMedia(bikeRouteId, props) {
    try {
      const data = await _http.HttpService.post('v1', `/ridesets/${bikeRouteId}/medias`, [], [], JSON.stringify(props));
      return (0, _ride.parseRideMedia)(data);
    } catch (err) {
      console.error('[BikeRouteService][addBikeRouteMedia]', err);
      throw err;
    }
  }
  static async addRideToBikeRoute(bikeRouteId, props) {
    try {
      const data = await _http.HttpService.post('v1', `/ridesets/${bikeRouteId}/rides`, [], [], JSON.stringify({
        rideSetId: bikeRouteId,
        priority: 'MAIN',
        ...props
      }));
      return parseBikeRouteRide(data);
    } catch (err) {
      console.error('[BikeRouteService][addRideToBikeRoute]', err);
      throw err;
    }
  }
  static async getBikeRoutes() {
    let {
      condensed,
      edition
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    try {
      const queryParams = [{
        key: 'condensed',
        value: Boolean(condensed)
      }];
      if (edition) queryParams.push({
        key: 'edition',
        value: Boolean(edition)
      });
      const res = await _http.HttpService.get('v1', '/ridesets', queryParams);
      return res.map(data => parseBikeRoute(data, _app.AppService.environment.backendUrl));
    } catch (err) {
      console.error('[BikeRouteService][getBikeRoutes]', err);
      throw err;
    }
  }
  static async getBikeRoute(id) {
    try {
      const data = await _http.HttpService.get('v1', `/ridesets/${id}`);
      return parseBikeRoute(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[BikeRouteService][getBikeRoute]', err);
      throw err;
    }
  }
  static async updateBikeRoute(id, _props) {
    const props = {
      ..._props
    };
    for (const key in props) {
      if (typeof key === 'undefined') delete props[key];
    }
    try {
      const data = await _http.HttpService.put('v1', `/ridesets/${id}`, [], [], JSON.stringify(props));
      return parseBikeRoute(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[BikeRouteService][updateBikeRoute]', err);
      throw err;
    }
  }
  static async updateBikeRouteIcon(bikeRouteId, icon) {
    try {
      const formData = new FormData();
      formData.append('icon', icon);
      const data = await _http.HttpService.put('v1', `/ridesets/${bikeRouteId}/icon`, [], [], formData);
      return parseBikeRoute(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[BikeRouteService][updateBikeRouteIcon]', err);
      throw err;
    }
  }
  static async updateBikeRoutePhoto(bikeRouteId, photoId, props) {
    try {
      const data = await _http.HttpService.put('v1', `/ridesets/${bikeRouteId}/photos/${photoId}`, [], [], JSON.stringify(props));
      return (0, _photo.parsePhoto)(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[BikeRouteService][updateBikeRoutePhoto]', err);
      throw err;
    }
  }
  static async updateBikeRouteMedia(bikeRouteId, mediaId, props) {
    try {
      const data = await _http.HttpService.put('v1', `/ridesets/${bikeRouteId}/medias/${mediaId}`, [], [], JSON.stringify(props));
      return (0, _ride.parseRideMedia)(data);
    } catch (err) {
      console.error('[BikeRouteService][updateBikeRouteMedia]', err);
      throw err;
    }
  }
  static async updateBikeRouteRide(bikeRouteId, rideId, props) {
    try {
      const data = await _http.HttpService.put('v1', `/ridesets/${bikeRouteId}/rides/${rideId}`, [], [], JSON.stringify(props));
      return parseBikeRouteRide(data);
    } catch (err) {
      console.error('[BikeRouteService][updateBikeRouteRide]', err);
      throw err;
    }
  }
  static async removeBikeRoute(id) {
    try {
      await _http.HttpService.delete('v1', `/ridesets/${id}`);
      return true;
    } catch (err) {
      console.error('[BikeRouteService][removeBikeRoute]', err);
      throw err;
    }
  }
  static async removeBikeRoutePhoto(bikeRouteId, photoId) {
    try {
      await _http.HttpService.delete('v1', `/ridesets/${bikeRouteId}/photos/${photoId}`);
      return true;
    } catch (err) {
      console.error('[BikeRouteService][removeBikeRoutePhoto]', err);
      throw err;
    }
  }
  static async removeBikeRouteMedia(bikeRouteId, mediaId) {
    try {
      await _http.HttpService.delete('v1', `/ridesets/${bikeRouteId}/medias/${mediaId}`);
      return true;
    } catch (err) {
      console.error('[BikeRouteService][removeBikeRouteMedia]', err);
      throw err;
    }
  }
  static async removeRideFromBikeRoute(bikeRouteId, rideId) {
    try {
      await _http.HttpService.delete('v1', `/ridesets/${bikeRouteId}/rides/${rideId}`);
      return true;
    } catch (err) {
      console.error('[BikeRouteService][removeRideFromBikeRoute]', err);
      throw err;
    }
  }
}
exports.BikeRouteService = BikeRouteService;
const priorities = exports.priorities = {
  MAIN: 'main',
  OPTION: 'option',
  VARIANT: 'variant'
};
function parseBikeRouteRide(_ref3) {
  let {
    ride_id: rideId,
    priority,
    order_atob: order,
    ride_atob_next: nextRideId,
    ride_atob_previous: prevRideId,
    order_btoa: returnOrder,
    ride_btoa_next: returnNextRideId,
    ride_btoa_previous: returnPrevRideId
  } = _ref3;
  return {
    rideId,
    priority: priorities[priority],
    order,
    nextRideId,
    prevRideId,
    returnOrder,
    returnNextRideId,
    returnPrevRideId
  };
}
function parseBikeRoute(_ref4, backendUrl) {
  let {
    id,
    creator,
    title,
    description,
    distance,
    area_wide,
    icon,
    icon_alt,
    geo_point_icon,
    geometry_condensed,
    geometry_condensed_return,
    count_ride_steps,
    rides,
    photos,
    medias,
    theme_primary_color,
    theme_secondary_color,
    publication_status
  } = _ref4;
  return new _models.BikeRoute(id, creator, title, description, distance, _ride.areas[area_wide], icon ? `${backendUrl}${icon}` : null, icon_alt, geo_point_icon, geometry_condensed, geometry_condensed_return, count_ride_steps || (rides || []).length, (rides || []).map(parseBikeRouteRide).sort((a, b) => a.order - b.order), photos.map(_ref5 => {
    let {
      title: photoTitle,
      ...data
    } = _ref5;
    return (0, _photo.parsePhoto)({
      title: photoTitle || title,
      ...data
    }, backendUrl);
  }).sort((a, b) => a.id && b.id ? a.id - b.id : 0), (medias || []).map(_ride.parseRideMedia), theme_primary_color, theme_secondary_color, _publicationStatus.publicationStatuses[publication_status]);
}