"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.collaborationTypes = exports.challengeTypes = exports.ChallengeTemplate = exports.Challenge = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _icons = require("../components/icons");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const challengeTypes = exports.challengeTypes = [{
  key: 'traveledDistance',
  Icon: _icons.DistanceIcon,
  labelKey: 'commons.challenges.types.distance',
  descriptionKey: 'commons.challenges.types.distance_description'
}, {
  key: 'cyclingDays',
  Icon: _icons.CalendarIcon,
  labelKey: 'commons.challenges.types.cycling_days',
  descriptionKey: 'commons.challenges.types.cycling_days_description'
}, {
  key: 'journeysCount',
  Icon: _icons.RideIcon,
  labelKey: 'commons.challenges.types.journeys',
  descriptionKey: 'commons.challenges.types.journeys_description'
}];
const collaborationTypes = exports.collaborationTypes = [{
  key: 'individual',
  Icon: _icons.PersonIcon,
  labelKey: 'commons.challenges.competition_types.individual',
  descriptionKey: 'commons.challenges.competition_types.individual_description'
}, {
  key: 'teams',
  Icon: _icons.FlagIcon,
  labelKey: 'commons.challenges.competition_types.teams',
  descriptionKey: 'commons.challenges.competition_types.teams_description'
}, {
  key: 'sites',
  Icon: _icons.CompanyIcon,
  labelKey: 'commons.challenges.competition_types.sites',
  descriptionKey: 'commons.challenges.competition_types.sites_description'
}, {
  key: 'global',
  Icon: _icons.PeopleIcon,
  labelKey: 'commons.challenges.competition_types.global',
  descriptionKey: 'commons.challenges.competition_types.global_description'
}];
class Challenge {
  constructor(id, type, collaborationType, traceType, startDate, endDate, title, description, photo, detailsUrl, nbMembers, progressValue, targetValue, publicationStatus, eventId, subGroupDisplay, group) {
    this.id = id;
    this.type = type;
    this.collaborationType = collaborationType;
    this.traceType = traceType;
    this.startDate = startDate;
    this.endDate = endDate;
    this.title = title;
    this.description = description;
    this.photo = photo;
    this.detailsUrl = detailsUrl;
    this.nbMembers = nbMembers;
    this.progressValue = progressValue;
    this.targetValue = targetValue;
    this.publicationStatus = publicationStatus;
    this.eventId = eventId;
    this.subGroupDisplay = subGroupDisplay;
    this.group = group;
    const now = (0, _moment.default)();
    if (endDate.isBefore(now)) this.period = 'past';else if (startDate.isAfter(now)) this.period = 'future';else this.period = 'current';
    this.progressPercentage = targetValue > 0 ? Math.min(Math.floor(progressValue / targetValue * 100), 100) : 0;
    this.overralProgressValue = collaborationType === 'global' ? progressValue : 0;
    this.overralProgressPercentage = targetValue > 0 ? Math.min(Math.floor(this.overralProgressValue / targetValue * 100), 100) : 100;
  }
  get daysLeft() {
    return this.endDate.diff((0, _moment.default)().startOf('day'), 'days') + 1;
  }
  get daysToWait() {
    return this.startDate.diff((0, _moment.default)().startOf('day'), 'days');
  }
}
exports.Challenge = Challenge;
class ChallengeTemplate {
  constructor(id, type, collaborationType, traceType, startDate, endDate, title, description, photo, icon, detailsUrl, targetValue, eventId, subGroupDisplay) {
    this.id = id;
    this.type = type;
    this.collaborationType = collaborationType;
    this.traceType = traceType;
    this.startDate = startDate;
    this.endDate = endDate;
    this.title = title;
    this.description = description;
    this.photo = photo;
    this.icon = icon;
    this.detailsUrl = detailsUrl;
    this.targetValue = targetValue;
    this.eventId = eventId;
    this.subGroupDisplay = subGroupDisplay;
    const now = (0, _moment.default)();
    if (endDate.isBefore(now)) this.period = 'past';else if (startDate.isAfter(now)) this.period = 'future';else this.period = 'current';
  }
  get daysLeft() {
    return this.endDate.diff((0, _moment.default)().startOf('day'), 'days') + 1;
  }
  get daysToWait() {
    return this.startDate.diff((0, _moment.default)().startOf('day'), 'days');
  }
}
exports.ChallengeTemplate = ChallengeTemplate;