"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommutingTripService = void 0;
exports.parseCommutingTripOccurrence = parseCommutingTripOccurrence;
exports.parseCommutingTripPotentialOccurrence = parseCommutingTripPotentialOccurrence;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _http = require("./http");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class CommutingTripService {
  static async createCommutingTrip(_ref) {
    let {
      employeeId: employee,
      title,
      home,
      work,
      bicyclePortions,
      isWorkToHome: display_reverse_order
    } = _ref;
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const data = await _http.HttpService.post('v1', `/commuting_trips`, [], [], JSON.stringify({
        employee,
        title,
        home: home.id,
        work: work.id,
        bicycle_portions: bicyclePortions.map(_ref2 => {
          let {
            start,
            end,
            order: commuting_trip_order
          } = _ref2;
          return {
            user_place_start: start.id,
            user_place_end: end.id,
            commuting_trip_order
          };
        }),
        display_reverse_order
      }));
      return parseCommutingTrip(data);
    } catch (err) {
      console.error('[CommutingTripService][createCommutingTrip]', err);
      throw new Error('commuting trip not created');
    }
  }
  static async updateCommutingTrip(id, _ref3) {
    let {
      employeeId: employee,
      title,
      home,
      work,
      bicyclePortions
    } = _ref3;
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const data = await _http.HttpService.patch('v1', `/commuting_trips/${id}`, [], [], JSON.stringify({
        employee,
        title,
        home: home.id,
        work: work.id,
        bicycle_portions: bicyclePortions.map(_ref4 => {
          let {
            start,
            end,
            order: commuting_trip_order
          } = _ref4;
          return {
            user_place_start: start.id,
            user_place_end: end.id,
            commuting_trip_order
          };
        })
      }));
      return parseCommutingTrip(data);
    } catch (err) {
      console.error('[CommutingTripService][updateCommutingTrip]', err);
      throw new Error('commuting trip not updated');
    }
  }
  static async getCommutingTrips(_ref5) {
    let {
      employeeId
    } = _ref5;
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const {
        results
      } = await _http.HttpService.get('v1', `/commuting_trips`, [{
        key: 'page',
        value: 1
      }, {
        key: 'page_size',
        value: 100
      }, {
        key: 'employee_id',
        value: employeeId
      }, {
        key: 'enabled',
        value: true
      }]);
      return results.map(parseCommutingTrip);
    } catch (err) {
      console.error('[CommutingTripService][getCommutingTrips]', err);
      throw err;
    }
  }
  static async getCommutingTrip(id) {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const data = await _http.HttpService.get('v1', `/commuting_trips/${id}`);
      return parseCommutingTrip(data);
    } catch (err) {
      console.error('[CommutingTripService][getCommutingTrip]', err);
      throw err;
    }
  }
  static async removeCommutingTrip(id) {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      await _http.HttpService.delete('v1', `/commuting_trips/${id}`);
      return true;
    } catch (err) {
      console.error('[CommutingTripService][removeCommutingTrip]', err);
      throw err;
    }
  }
  static async getOccurrences(_ref6) {
    let {
      employeeId,
      page,
      pageSize,
      orderBy,
      order,
      period,
      dateStart,
      dateEnd
    } = _ref6;
    try {
      const params = [{
        key: 'employee_id',
        value: employeeId
      }, {
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }];
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      if (period === 'custom' && (dateStart === undefined || dateEnd === undefined)) throw new Error('dateStart & dateEnd are required in custom mode');
      if (period !== 'custom' && (dateStart !== undefined || dateEnd !== undefined)) throw new Error('dateStart & dateEnd are incompatible with this period mode');
      if (orderBy && order) {
        let value;
        if (orderBy === 'id') value = 'id';
        if (value) {
          if (order === 'desc') value = `-${value}`;
          params.push({
            key: 'ordering',
            value
          });
        }
      }
      if (period) params.push({
        key: 'period',
        value: period
      });
      if (dateStart) params.push({
        key: 'date_start',
        value: dateStart.format('YYYY-MM-DD')
      });
      if (dateEnd) params.push({
        key: 'date_end',
        value: dateEnd.format('YYYY-MM-DD')
      });
      const {
        count,
        next,
        results
      } = await _http.HttpService.get('v1', `/commuting_trip_occurrences`, params);
      return {
        count,
        next,
        occurrences: results.map(parseCommutingTripOccurrence)
      };
    } catch (err) {
      console.error('[CommutingTripService][getOccurrences]', err);
      throw err;
    }
  }
  static async addOccurrence(commutingTripId, _ref7) {
    let {
      date,
      direction
    } = _ref7;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.post('v1', `/commuting_trip_occurrences`, [], [], JSON.stringify({
        commuting_trip: commutingTripId,
        date,
        direction: (0, _user.toBackendTripDirection)(direction)
      }));
      return parseCommutingTripOccurrence(data);
    } catch (err) {
      console.error('[CommutingTripService][addOccurrence]', err);
      throw typeof err === 'object' && err && 'non_field_errors' in err && Array.isArray(err.non_field_errors) ? new Error(err.non_field_errors[0]) : err;
    }
  }
  static async updateOccurrence(id, _ref8) {
    let {
      excluded
    } = _ref8;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.patch('v1', `/commuting_trip_occurrences/${id}`, [], [], JSON.stringify({
        manually_excluded: excluded
      }));
      return parseCommutingTripOccurrence(data);
    } catch (err) {
      console.error('[CommutingTripService][updateOccurrence]', err);
      throw err;
    }
  }
  static async getPotentialOccurrences(_ref9) {
    let {
      fmdConfig,
      employeeId,
      page,
      pageSize,
      orderBy,
      order,
      period,
      dateStart,
      dateEnd
    } = _ref9;
    try {
      const params = [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }];
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      if (period === 'custom' && (dateStart === undefined || dateEnd === undefined)) throw new Error('dateStart & dateEnd are required in custom mode');
      if (period !== 'custom' && (dateStart !== undefined || dateEnd !== undefined)) throw new Error('dateStart & dateEnd are incompatible with this period mode');
      if (orderBy && order) {
        let value;
        if (orderBy === 'id') value = 'id';
        if (orderBy === 'date') value = 'date';
        if (value) {
          if (order === 'desc') value = `-${value}`;
          params.push({
            key: 'ordering',
            value
          });
        }
      }
      if (period) params.push({
        key: 'period',
        value: period
      });
      if (dateStart) params.push({
        key: 'date_start',
        value: dateStart.format('YYYY-MM-DD')
      });
      if (dateEnd) params.push({
        key: 'date_end',
        value: dateEnd.format('YYYY-MM-DD')
      });
      const {
        count,
        next,
        results
      } = await _http.HttpService.get('v5', `/employees/${employeeId}/reference_trip_occurrences`, params);
      return {
        count,
        next,
        occurrences: results.map(parseCommutingTripPotentialOccurrence).filter(_ref10 => {
          let {
            date
          } = _ref10;
          const day = _models.daysMap[date.get('day')];
          return fmdConfig.compensableDays[day];
        })
      };
    } catch (err) {
      console.error('[CommutingTripService][getPotentialOccurrences]', err);
      throw err;
    }
  }
}
exports.CommutingTripService = CommutingTripService;
function parseCommutingTrip(_ref11) {
  let {
    id,
    title,
    home: homeId,
    home_address,
    home_point,
    work: workId,
    work_address,
    work_point,
    bicycle_portions,
    display_reverse_order: isWorkToHome
  } = _ref11;
  const bicyclePortions = (bicycle_portions || []).sort((a, b) => a.commuting_trip_order - b.commuting_trip_order);
  if (isWorkToHome) bicyclePortions.reverse();
  const start = new _models.Place(homeId, home_point, {
    primaryText: home_address
  });
  const end = new _models.Place(workId, work_point, {
    primaryText: work_address
  });
  return new _models.CommutingTrip(id, title || `Trajet vélotaf ${id}`, isWorkToHome ? end : start, isWorkToHome ? start : end, bicyclePortions.map(_ref12 => {
    let {
      id,
      user_place_start,
      geo_start,
      geo_start_title,
      user_place_end,
      geo_end,
      geo_end_title,
      geometry_start_end,
      geometry_end_start,
      distance_in_meters_start_end,
      distance_in_meters_end_start
    } = _ref12;
    const portionStart = new _models.UserPlace(user_place_start, 'other', geo_start_title, geo_start, geo_start_title, (0, _moment.default)(), null);
    const portionEnd = new _models.UserPlace(user_place_end, 'other', geo_end_title, geo_end, geo_end_title, (0, _moment.default)(), null);
    return isWorkToHome ? {
      id,
      start: portionEnd,
      end: portionStart,
      geometry: geometry_end_start,
      distance: distance_in_meters_end_start
    } : {
      id,
      start: portionStart,
      end: portionEnd,
      geometry: geometry_start_end,
      distance: distance_in_meters_start_end
    };
  }), isWorkToHome);
}
function parseCommutingTripOccurrence(_ref13) {
  let {
    id,
    commuting_trip,
    date,
    direction,
    certified,
    compensated,
    manually_excluded
  } = _ref13;
  return new _models.CommutingTripOccurrence(id, commuting_trip, (0, _moment.default)(date), date, (0, _user.toTripDirection)(direction), certified === 'CERTIFIED' || certified === 'CERTIFIED_MANUALLY_FLAGGED_BY_USER', certified === 'CERTIFIED_MANUALLY_FLAGGED_BY_USER' || certified === 'NOT_CERTIFIED_MANUAL_DECLARATION', compensated || false, manually_excluded || false);
}
function parseCommutingTripPotentialOccurrence(_ref14) {
  let {
    id,
    date,
    direction,
    candidate,
    certified_by_geovelo,
    manually_created,
    enabled,
    user_reference_trip,
    user_trace
  } = _ref14;
  return new _models.CommutingTripPotentialOccurrence(id, (0, _moment.default)(date), date, (0, _user.toTripDirection)(direction), candidate, certified_by_geovelo, manually_created, enabled, user_reference_trip ? (0, _user.parseUserReferenceTrip)(user_reference_trip) : null, user_reference_trip?.commuting_trip ? parseCommutingTrip(user_reference_trip.commuting_trip) : null, user_trace);
}