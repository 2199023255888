"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripService = void 0;
var _center = _interopRequireDefault(require("@turf/center"));
var _nearestPointOnLine = _interopRequireDefault(require("@turf/nearest-point-on-line"));
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _computedRoute = require("./computed-route");
var _cyclingProfile = require("./cycling-profile");
var _http = require("./http");
var _poiAnnotation = require("./poi-annotation");
var _route = require("./route");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class TripService {
  static async getUserTrips(_ref) {
    let {
      query
    } = _ref;
    if (!_user.UserService.currentUser) return [];
    try {
      const data = await _http.HttpService.get('v4', `/users/${_user.UserService.currentUser.id}/trips`, [{
        key: 'query',
        value: query
      }]);
      const trips = data.map(parseTrip);
      return trips;
    } catch (err) {
      console.error('[TripService][getUserTrips]', err);
      return [];
    }
  }
  static async getUserTrip(id) {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const data = await _http.HttpService.get('v4', `/users/${_user.UserService.currentUser.id}/trips/${id}`);
      const trip = parseTrip(data);
      return trip;
    } catch (err) {
      console.error('[TripService][getUserTrip]', err);
      throw err;
    }
  }
  static async create(search, _ref2, route) {
    let {
      title,
      nbDays,
      departureDate,
      arrivalDate
    } = _ref2;
    try {
      const params = {
        ...search.toParams(),
        numberOfDays: nbDays,
        poiIdsPerDay: Array(nbDays).fill([])
      };
      if (departureDate) params.datetimeOfDeparture = departureDate.toISOString();
      if (arrivalDate) params.datetimeOfDeparture = arrivalDate.toISOString();
      const res = await _http.HttpService.post('v2', `/trips`, [{
        key: 'instructions',
        value: false
      }, {
        key: 'elevations',
        value: false
      }, {
        key: 'geometry',
        value: true
      }, {
        key: 'single_result',
        value: true
      }, {
        key: 'energy',
        value: false
      }, {
        key: 'bike_parkings',
        value: false
      }], [], JSON.stringify(params));
      const computedRoutes = res.map(data => (0, _computedRoute.parseComputedRoute)(data[0]));
      let routes;
      if (route) {
        routes = await Promise.all([_route.RouteService.saveRoute({
          type: 'USER_TRIP',
          computedRouteId: route instanceof _models.ComputedRoute ? route.id : route.computedRouteId
        })]);
      } else {
        routes = await Promise.all(computedRoutes.map(_ref3 => {
          let {
            id: computedRouteId
          } = _ref3;
          return _route.RouteService.saveRoute({
            type: 'USER_TRIP',
            computedRouteId
          });
        }));
      }
      const {
        id: tripId
      } = await TripService.save({
        title,
        search,
        departureDate,
        arrivalDate
      });
      await Promise.all(routes.map((_ref4, index) => {
        let {
          id: routeId
        } = _ref4;
        return TripService.addStep({
          tripId,
          routeId,
          dayIndex: index + 1,
          search
        });
      }));
      const trip = await TripService.getUserTrip(tripId);
      return {
        trip,
        routes
      };
    } catch (err) {
      console.error('[TripService][create]', err);
      throw err;
    }
  }
  static async save(_ref5) {
    let {
      title,
      search,
      departureDate,
      arrivalDate
    } = _ref5;
    const {
      bikeDetails: {
        profile,
        eBike: e_bike
      }
    } = search.toParams();
    const params = {
      title,
      profile,
      e_bike
    };
    if (departureDate) params.departure_datetime = departureDate.toISOString();
    if (arrivalDate) params.arrival_datetime = arrivalDate.toISOString();
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.post('v2', `/users/${_user.UserService.currentUser.id}/trips`, [], [], JSON.stringify(params));
      const trip = parseTrip(data);
      return trip;
    } catch (err) {
      console.error('[TripService][save]', err);
      throw err;
    }
  }
  static async addStep(_ref6) {
    let {
      tripId,
      routeId,
      dayIndex: day_index,
      search,
      poiAnnotationIds,
      departureAccomodationAnnotationId,
      arrivalAccomodationAnnotationId
    } = _ref6;
    const {
      bikeDetails: {
        profile,
        eBike: e_bike
      }
    } = search.toParams();
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.post('v2', `/users/${_user.UserService.currentUser.id}/trips/${tripId}/steps`, [], [], JSON.stringify({
        itinerary: routeId,
        day_index,
        profile,
        e_bike,
        itinerary_poi_annotations: poiAnnotationIds || [],
        departure_accommodation: departureAccomodationAnnotationId || null,
        arrival_accommodation: arrivalAccomodationAnnotationId || null
      }));
      const step = parseTripStep(data);
      return step;
    } catch (err) {
      console.error('[TripService][addStep]', err);
      throw err;
    }
  }
  static async updateTrip(id, _ref7) {
    let {
      title,
      description,
      departureDate
    } = _ref7;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const body = {};
      if (title) body.title = title;
      if (description !== undefined) body.description = description;
      if (departureDate) body.departure_datetime = departureDate.toISOString();
      const data = await _http.HttpService.put('v4', `/users/${_user.UserService.currentUser.id}/trips/${id}`, [], [], JSON.stringify(body));
      const trip = parseTrip(data);
      return trip;
    } catch (err) {
      console.error('[TripService][updateTrip]', err);
      throw err;
    }
  }
  static async updateStep(tripStepId, _ref8) {
    let {
      tripId,
      dayIndex,
      routeId,
      search,
      poiAnnotationIds,
      departureAccomodationAnnotationId,
      arrivalAccomodationAnnotationId,
      datetimeOfDeparture
    } = _ref8;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      let body = {};
      if (dayIndex !== undefined) body.day_index = dayIndex;
      if (routeId) body.itinerary = routeId;
      if (search) {
        const {
          bikeDetails: {
            profile,
            eBike: e_bike
          }
        } = search.toParams();
        body = {
          ...body,
          profile,
          e_bike
        };
      }
      if (poiAnnotationIds) body.itinerary_poi_annotations = poiAnnotationIds;
      if (departureAccomodationAnnotationId !== undefined) body.departure_accommodation = departureAccomodationAnnotationId;
      if (arrivalAccomodationAnnotationId !== undefined) body.arrival_accommodation = arrivalAccomodationAnnotationId;
      if (datetimeOfDeparture) body.departure_datetime = datetimeOfDeparture.toISOString();else if (datetimeOfDeparture === null) body.departure_datetime = null;
      const data = await _http.HttpService.put('v2', `/users/${_user.UserService.currentUser.id}/trips/${tripId}/steps/${tripStepId}`, [], [], JSON.stringify(body));
      const step = parseTripStep(data);
      return step;
    } catch (err) {
      console.error('[TripService][updateStep]', err);
      throw err;
    }
  }
  static async splitStep(trip, tripStepIndex, routes, searches, poiCategories) {
    try {
      const tripStep = trip.steps?.[tripStepIndex];
      if (!tripStep) throw new Error('trip has no steps');
      const tripId = trip.id;
      const oldArrivalAccomodationAnnotationId = tripStep.arrivalAccomodationAnnotationId;
      const route = routes[tripStepIndex];
      const geometry = route?.geometry;
      if (!geometry) throw new Error('route has no geometry');
      const newTripSteps = [...trip.steps];
      const newRoutes = [...routes];
      const newSearches = [...searches];
      const search = newSearches[tripStepIndex].clone();

      // get step to split center
      const {
        geometry: {
          coordinates: [lng, lat]
        },
        properties: {
          index: newWayPointGeometryIndex
        }
      } = (0, _nearestPointOnLine.default)(geometry, (0, _center.default)(geometry));

      // add new way point to step to split
      const id = new Date().getTime();
      const newWayPoint = new _models.Place(id, {
        type: 'Point',
        coordinates: [lng, lat]
      });
      search.wayPoints.splice(route.wayPoints.length - 1, 0, newWayPoint);

      // reorder step to split way points
      const newWayPoints = await _computedRoute.ComputedRouteService.reorder({
        search
      });
      const newWayPointIndex = newWayPoints.findIndex(wayPoint => wayPoint?.id === id);

      // update search corresponding to the step to split
      newSearches[tripStepIndex].wayPoints = newWayPoints.slice().splice(0, newWayPointIndex + 1);

      // add search corresponding to the new step
      const newSearch = search.clone();
      newSearch.wayPoints = newWayPoints.slice().splice(newWayPointIndex);
      newSearches.splice(tripStepIndex + 1, 0, newSearch);

      // get poi annotations for step to split
      const poiAnnotations = tripStep.poiAnnotationIds ? await _poiAnnotation.PoiAnnotationService.getPoiAnnotations(tripStep.poiAnnotationIds, poiCategories.reduce((res, _ref9) => {
        let {
          id,
          code
        } = _ref9;
        res[id] = code;
        return res;
      }, {})) : [];
      const poiAnnotationsMap = poiAnnotations.reduce((res, _ref10) => {
        let {
          id,
          poi: {
            location: {
              point
            }
          }
        } = _ref10;
        const {
          properties: {
            index: geometryIndex
          }
        } = (0, _nearestPointOnLine.default)(geometry, point);
        res[id] = geometryIndex !== undefined && newWayPointGeometryIndex !== undefined && geometryIndex > newWayPointGeometryIndex ? tripStepIndex + 1 : tripStepIndex;
        return res;
      }, {});
      const [{
        updatedTripStep,
        updatedRoute
      }, {
        newTripStep,
        newRoute
      }] = await Promise.all([
      // update current step
      (async () => {
        // compute new route
        const {
          computedRoutes: [{
            id: computedRouteId
          }]
        } = await _computedRoute.ComputedRouteService.compute(newSearches[tripStepIndex], {
          singleResult: true
        });

        // update route
        const updatedRoute = route instanceof _models.Route ? await _route.RouteService.updateRoute(route.id, {
          type: 'USER_TRIP',
          computedRouteId
        }) : await _route.RouteService.saveRoute({
          type: 'USER_TRIP',
          computedRouteId
        });

        // update step
        const updatedTripStep = await TripService.updateStep(tripStep.id, {
          tripId,
          poiAnnotationIds: (tripStep.poiAnnotationIds || []).filter(id => poiAnnotationsMap[id] === tripStepIndex),
          arrivalAccomodationAnnotationId: null
        });
        return {
          updatedTripStep,
          updatedRoute
        };
      })(),
      // create new step
      (async () => {
        const newTripStepIndex = tripStepIndex + 1;

        // compute new route
        const {
          computedRoutes: [{
            id: computedRouteId
          }]
        } = await _computedRoute.ComputedRouteService.compute(newSearches[newTripStepIndex], {
          singleResult: true
        });

        // save new route
        const newRoute = await _route.RouteService.saveRoute({
          type: 'USER_TRIP',
          computedRouteId
        });

        // create new step
        const newTripStep = await TripService.addStep({
          tripId,
          dayIndex: newTripStepIndex + 1,
          routeId: newRoute.id,
          search: newSearches[newTripStepIndex],
          poiAnnotationIds: (tripStep.poiAnnotationIds || []).filter(id => poiAnnotationsMap[id] === newTripStepIndex),
          arrivalAccomodationAnnotationId: oldArrivalAccomodationAnnotationId
        });
        return {
          newTripStep,
          newRoute
        };
      })()]);
      newTripSteps.splice(tripStepIndex, 1, updatedTripStep, newTripStep);
      newRoutes.splice(tripStepIndex, 1, updatedRoute, newRoute);

      // update day indices of next steps
      await Promise.all(newTripSteps.map((_ref11, index) => {
        let {
          id,
          dayIndex
        } = _ref11;
        return index + 1 !== dayIndex ? TripService.updateStep(id, {
          tripId,
          dayIndex: index + 1
        }) : null;
      }));
      const newTrip = await TripService.getUserTrip(tripId);
      return {
        trip: newTrip,
        routes: newRoutes,
        searches: newSearches
      };
    } catch (err) {
      console.error('[TripService][splitStep]', err);
      throw err;
    }
  }
  static async mergeSteps(trip, firstTripStepIndex, routes, searches) {
    try {
      const firstTripStep = trip.steps?.[firstTripStepIndex];
      const secondTripStep = trip.steps?.[firstTripStepIndex + 1];
      if (!firstTripStep || !secondTripStep) throw new Error('trip has no steps');
      const tripId = trip.id;
      const route = routes[firstTripStepIndex];
      const newTripSteps = [...trip.steps];
      const newRoutes = [...routes];
      const newSearches = [...searches];

      // update search corresponding to the step to merge
      newSearches[firstTripStepIndex].wayPoints = [...searches[firstTripStepIndex].wayPoints.slice(), ...searches[firstTripStepIndex + 1].wayPoints.slice(1)];

      // remove deleted step search
      newSearches.splice(firstTripStepIndex + 1, 1);
      const [{
        updatedStep,
        updatedRoute
      }] = await Promise.all([
      // update first step
      (async () => {
        // compute new route
        const {
          computedRoutes: [{
            id: computedRouteId
          }]
        } = await _computedRoute.ComputedRouteService.compute(newSearches[firstTripStepIndex], {
          singleResult: true
        });

        // update route
        const updatedRoute = route instanceof _models.Route ? await _route.RouteService.updateRoute(route.id, {
          type: 'USER_TRIP',
          computedRouteId
        }) : await _route.RouteService.saveRoute({
          type: 'USER_TRIP',
          computedRouteId
        });

        // update step
        const updatedStep = await TripService.updateStep(firstTripStep.id, {
          tripId,
          poiAnnotationIds: [...(firstTripStep.poiAnnotationIds || []), ...(secondTripStep.poiAnnotationIds || [])],
          arrivalAccomodationAnnotationId: secondTripStep.arrivalAccomodationAnnotationId
        });
        return {
          updatedRoute,
          updatedStep
        };
      })(),
      // remove merged step
      (async () => {
        // remove step
        await TripService.removeTripStep(secondTripStep.id, {
          tripId
        });

        // remove route
        if (secondTripStep.route) await _route.RouteService.remove(secondTripStep.route.id);
      })()]);
      newTripSteps.splice(firstTripStepIndex, 2, updatedStep);
      newRoutes.splice(firstTripStepIndex, 2, updatedRoute);

      // update day indices of next steps
      await Promise.all(newTripSteps.map((_ref12, index) => {
        let {
          id,
          dayIndex
        } = _ref12;
        return index + 1 !== dayIndex ? TripService.updateStep(id, {
          tripId,
          dayIndex: index + 1
        }) : null;
      }));
      const newTrip = await TripService.getUserTrip(tripId);
      return {
        trip: newTrip,
        routes: newRoutes,
        searches: newSearches
      };
    } catch (err) {
      console.error('[TripService][mergeSteps]', err);
      throw err;
    }
  }
  static async removeUserTrip(id) {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      await _http.HttpService.delete('v2', `/users/${_user.UserService.currentUser.id}/trips/${id}`);
      return true;
    } catch (err) {
      console.error('[TripService][removeUserTrip]', err);
      throw err;
    }
  }
  static async removeTripStep(id, _ref13) {
    let {
      tripId
    } = _ref13;
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      await _http.HttpService.delete('v2', `/users/${_user.UserService.currentUser.id}/trips/${tripId}/steps/${id}`);
      return true;
    } catch (err) {
      console.error('[TripService][removeTripStep]', err);
      throw err;
    }
  }
}
exports.TripService = TripService;
function parseTripStep(_ref14) {
  let {
    id,
    day_index,
    departure_datetime,
    arrival_datetime,
    profile,
    e_bike,
    itinerary_poi_annotations,
    departure_accommodation,
    arrival_accommodation,
    itinerary
  } = _ref14;
  return new _models.TripStep(id, day_index, departure_datetime ? (0, _moment.default)(departure_datetime) : null, arrival_datetime ? (0, _moment.default)(arrival_datetime) : null, _cyclingProfile.backendCyclingProfiles[profile], e_bike, itinerary_poi_annotations, departure_accommodation, arrival_accommodation, typeof itinerary === 'number' ? {
    id: itinerary
  } : itinerary);
}
function parseTrip(_ref15) {
  let {
    id,
    title,
    description,
    departure_datetime,
    arrival_datetime,
    nb_days,
    distance,
    profile,
    e_bike,
    steps
  } = _ref15;
  return new _models.Trip(id || -1, title || '', description || '', typeof departure_datetime === 'string' ? (0, _moment.default)(departure_datetime) : departure_datetime, typeof arrival_datetime === 'string' ? (0, _moment.default)(arrival_datetime) : arrival_datetime, nb_days, distance, profile ? _cyclingProfile.backendCyclingProfiles[profile] : undefined, e_bike, steps?.map(parseTripStep).sort((a, b) => typeof a.dayIndex === 'number' && typeof b.dayIndex === 'number' ? a.dayIndex - b.dayIndex : 0));
}