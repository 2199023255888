"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GeocoderService = void 0;
var _models = require("../models");
var _http = require("./http");
class GeocoderService {
  static async autocomplete(input, _ref, callback) {
    let {
      center,
      nbResults
    } = _ref;
    try {
      const body = [{
        key: 'input',
        value: input
      }, {
        key: 'result_size',
        value: nbResults || 5
      }];
      if (center) {
        body.push({
          key: 'latitude',
          value: center.lat
        }, {
          key: 'longitude',
          value: center.lng
        });
      }
      const data = await _http.HttpService.get('v2', '/geocoder/autocomplete', body);
      const places = data.filter(res => res && res.location).map(parseAutocompleteResult);
      if (callback) callback(places);
      return places;
    } catch (err) {
      console.error('[GeocoderService][autocomplete]', err);
      if (callback) callback([]);
      return [];
    }
  }
  static async reverseGeocode(id, requestedLocation, callback) {
    const {
      coordinates: [lng, lat]
    } = requestedLocation;
    if (!lng || !lat) throw new Error('Location is not defined');
    try {
      const data = await _http.HttpService.get('v2', '/geocoder/reverse', [{
        key: 'latitude',
        value: lat
      }, {
        key: 'longitude',
        value: lng
      }]);
      const place = parseReverseGeocodeResult({
        id,
        requestedLocation,
        ...data
      });
      callback?.(place);
      return place;
    } catch (err) {
      console.error('[GeocoderService][reverse]', err);
      throw err;
    }
  }
}
exports.GeocoderService = GeocoderService;
const autocompleteResultTypes = {
  hotel: 'hotel',
  restaurant: 'restaurant',
  fast_food: 'restaurant',
  park: 'garden',
  toilets: 'toilets',
  drinking_water: 'drinkingWater',
  charging_station: 'chargingStation',
  compressed_air: 'bicyclePump',
  bicycle: 'bicycleRepair',
  bicycle_parking: 'bicycleParking',
  train_station: 'trainStation',
  information: 'information',
  picnic_table: 'picnic',
  viewpoint: 'viewPoint',
  museum: 'museum',
  beach: 'beach',
  camp_site: 'camping',
  castle: 'castle',
  hostel: 'collective',
  castle_wall: 'fortification',
  ruins: 'ruins',
  fountain: 'fountain',
  monument: 'smallHeritage',
  place_of_worship: 'religious',
  industrial: 'industry',
  sports_centre: 'sport',
  stadium: 'sport',
  city: 'city',
  town: 'town'
};
function parseAutocompleteResult(_ref2) {
  let {
    main_text: primaryText,
    secondary_text: secondaryText,
    location: [lat, lng],
    osm_id,
    osm_key,
    osm_value
  } = _ref2;
  return new _models.Place(osm_id, {
    type: 'Point',
    coordinates: [lng, lat]
  }, {
    primaryText: primaryText.replace(/\n/g, ' '),
    secondaryText: secondaryText.replace(/\n/g, ' ')
  }, undefined, (osm_key === 'railway' && osm_value === 'station' ? 'trainStation' : autocompleteResultTypes[osm_value]) || undefined);
}
function parseReverseGeocodeResult(_ref3) {
  let {
    id,
    title,
    address,
    streetNumber,
    streetName,
    city,
    country,
    location,
    requestedLocation
  } = _ref3;
  let primaryText;
  if (streetName) primaryText = streetNumber ? `${streetNumber}, ${streetName}` : streetName;else if (title) primaryText = title;else if (address) primaryText = address;
  if (!primaryText) throw new Error('invalid result');
  let secondaryText;
  if (streetName || title) secondaryText = city && country ? `${city}, ${country}` : city || country || undefined;
  return new _models.Place(id, location || requestedLocation, primaryText ? {
    primaryText,
    secondaryText,
    city: city || undefined
  } : undefined);
}