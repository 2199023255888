"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BadgeService = void 0;
exports.parseBadgeLevels = parseBadgeLevels;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _http = require("./http");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class BadgeService {
  static async getBadgeLevels() {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const [res1, res3] = await Promise.all([_http.HttpService.get('v1', `/users/${_user.UserService.currentUser.id}/game_level_badges`), _http.HttpService.get('v2', `/users/${_user.UserService.currentUser.id}/game_achievements`)]);
      return {
        levels: parseBadgeLevels(res1),
        daysBadges: res3.map(_ref => {
          let {
            title: key,
            date
          } = _ref;
          return {
            key: key,
            date: (0, _moment.default)(date)
          };
        }).sort((a, b) => a.date.isBefore(b.date) ? -1 : 1).filter(_ref2 => {
          let {
            key
          } = _ref2;
          return _models.dayBadges.includes(key);
        })
      };
    } catch (err) {
      console.error('[BadgeService][getBadgeLevels]', err);
      throw err;
    }
  }
}
exports.BadgeService = BadgeService;
const backendBadgeLevelKeys = [
// 'cycling_distance',
'cycling_max_distance', 'night_bird', 'positive_elevation',
// 'cycling_days',
// 'traces_max_total_distance_over_one_month',
'report', 'commuting_traces_max_count_over_one_month'];
const badgeLevelKeys = {
  // cycling_days: 'regularity',
  // cycling_distance: 'cyclingDistance',
  report: 'contributor',
  cycling_max_distance: 'cyclingMaxDistance',
  night_bird: 'nightBird',
  positive_elevation: 'verticalGain',
  // traces_max_total_distance_over_one_month: 'cyclingMaxDistanceOverOneMonth',
  commuting_traces_max_count_over_one_month: 'commutingTracesMaxCountOverOneMonth'
};
function parseBadgeLevels(_ref3) {
  let {
    level_badges
  } = _ref3;
  return backendBadgeLevelKeys.reduce((res, _key) => {
    const data = level_badges.find(_ref4 => {
      let {
        code
      } = _ref4;
      return code === _key;
    });
    if (data) {
      const {
        level,
        level_thresholds,
        progress
      } = data;
      const key = badgeLevelKeys[_key];
      if (level_thresholds) {
        res[key] = new Array(Math.min(level_thresholds.length, _models.badgeLevelsMap[key].images.length)).fill(null).map((_, index) => ({
          achieved: level >= index + 1,
          level: index + 1,
          value: level_thresholds[index],
          progress
        }));
      } else {
        res[key] = [];
      }
    }
    return res;
  }, {});
}