"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _apple = require("./apple");
Object.keys(_apple).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _apple[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _apple[key];
    }
  });
});
var _bluesky = require("./bluesky");
Object.keys(_bluesky).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _bluesky[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _bluesky[key];
    }
  });
});
var _facebook = require("./facebook");
Object.keys(_facebook).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _facebook[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _facebook[key];
    }
  });
});
var _facebookMinimal = require("./facebook-minimal");
Object.keys(_facebookMinimal).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _facebookMinimal[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _facebookMinimal[key];
    }
  });
});
var _facebookSquare = require("./facebook-square");
Object.keys(_facebookSquare).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _facebookSquare[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _facebookSquare[key];
    }
  });
});
var _google = require("./google");
Object.keys(_google).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _google[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _google[key];
    }
  });
});
var _instagram = require("./instagram");
Object.keys(_instagram).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _instagram[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _instagram[key];
    }
  });
});
var _instagramMinimal = require("./instagram-minimal");
Object.keys(_instagramMinimal).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _instagramMinimal[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _instagramMinimal[key];
    }
  });
});
var _linkedin = require("./linkedin");
Object.keys(_linkedin).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _linkedin[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _linkedin[key];
    }
  });
});
var _linkedinMinimal = require("./linkedin-minimal");
Object.keys(_linkedinMinimal).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _linkedinMinimal[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _linkedinMinimal[key];
    }
  });
});
var _x = require("./x");
Object.keys(_x).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _x[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _x[key];
    }
  });
});
var _xMinimal = require("./x-minimal");
Object.keys(_xMinimal).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _xMinimal[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _xMinimal[key];
    }
  });
});