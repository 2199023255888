"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CoveloService = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _coveloContact = require("../models/covelo-contact");
var _http = require("./http");
var _route = require("./route");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class CoveloService {
  static async compute(search) {
    try {
      const params = [{
        key: 'start_location',
        value: search.wayPoints[0]?.asParam || false
      }, {
        key: 'end_location',
        value: search.wayPoints[search.wayPoints.length - 1]?.asParam || false
      }, {
        key: 'radius',
        value: 1000
      }];
      const data = await _http.HttpService.get('v2', `/guided_itinerary`, params);
      return data.map(parseSavedItinerary);
    } catch (err) {
      console.error('[CoveloService][compute]', err);
      throw err;
    }
  }
  static async contact(guidedItineraryId, props) {
    try {
      const data = await _http.HttpService.post('v2', `/guided_itinerary/${guidedItineraryId}/contacts`, [], [], JSON.stringify({
        ...props
      }));
      return data;
    } catch (err) {
      console.error('[CoveloService][contact]', err);
      throw err;
    }
  }
  static async getUserContacts() {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.get('v2', `/users/${_user.UserService.currentUser.id}/guided_itinerary_contacts`);
      const coveloUserContacts = data.map(parseCoveloContact);
      return coveloUserContacts;
    } catch (err) {
      console.error('[CoveloService][getUserContacts]', err);
      throw err;
    }
  }
}
exports.CoveloService = CoveloService;
function parseSavedItinerary(_ref) {
  let {
    id,
    created,
    description,
    title,
    type,
    waypoints,
    distances,
    duration,
    estimatedDatetimeOfDeparture,
    estimatedDatetimeOfArrival,
    sections,
    computedRouteId
  } = _ref;
  let prevWayPointIndex = 0;
  return new _models.Route(id, (0, _moment.default)(created), description, _route.routeLabelKeys[title], _route.routeTypes[type], waypoints.map(_route.parseWayPoint), distances, duration, (0, _moment.default)(estimatedDatetimeOfDeparture), (0, _moment.default)(estimatedDatetimeOfArrival), sections.map((sectionData, index) => {
    const section = (0, _route.parseSection)(sectionData, index, prevWayPointIndex);
    prevWayPointIndex += section.wayPointsIndices.length - 2;
    return section;
  }), computedRouteId);
}
function parseCoveloContact(_ref2) {
  let {
    id,
    created,
    email,
    phone_number,
    itinerary,
    message
  } = _ref2;
  return new _coveloContact.CoveloContact(id, (0, _moment.default)(created), email, phone_number, (0, _route.parseRoute)(itinerary), message);
}