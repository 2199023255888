"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScenarioService = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _http = require("./http");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class ScenarioService {
  static async getScenarios(username) {
    try {
      const {
        trafficFlowScenarios
      } = await _http.HttpService.post('v1', '/traffic_flow_scenario/list', [], [], JSON.stringify({
        username
      }));
      const scenarios = trafficFlowScenarios.map(scenario => parseScenario(scenario));
      return scenarios;
    } catch (err) {
      console.error('[ScenarioService][getScenarios]', err);
      throw err;
    }
  }
  static async getScenario(scenarioId) {
    try {
      const result = await _http.HttpService.post('v1', '/traffic_flow_scenario/result', [], [], JSON.stringify({
        scenarioId
      }));
      return parseScenarioDetails(result);
    } catch (err) {
      console.error('[ScenarioService][getScenario]', err);
      throw err;
    }
  }
  static async createScenario(areaId, username, title, modifications) {
    try {
      const featureChanges = [];
      const closedRouteLinkId = [];
      Object.keys(modifications).map(key => {
        const id = parseInt(key);
        const {
          amenagement,
          blocked,
          highway,
          surface
        } = modifications[id];
        if (amenagement) featureChanges.push({
          routeLinkId: id,
          feature: 'amenagement',
          value: amenagement
        });
        if (highway) featureChanges.push({
          routeLinkId: id,
          feature: 'highway',
          value: highway
        });
        if (surface) featureChanges.push({
          routeLinkId: id,
          feature: 'surface',
          value: surface
        });
        if (blocked) closedRouteLinkId.push(id);
      });
      const changes = {
        areaId,
        username,
        scenarioDescription: title,
        closedRouteLinkId,
        featureChanges
      };
      const {
        scenarioId
      } = await _http.HttpService.post('v1', '/traffic_flow_scenario/generate', [], [], JSON.stringify(changes));
      return scenarioId;
    } catch (err) {
      console.error('[ScenarioService][createScenario]', err);
      throw err;
    }
  }
  static async getNominalGraph(areaId) {
    try {
      const result = await _http.HttpService.post('v1', '/traffic_flow_scenario/nominal', [], [], JSON.stringify({
        areaId
      }));
      return parseScenarioDetails(result);
    } catch (err) {
      console.error('[ScenarioService][getNominalGraph]', err);
      throw err;
    }
  }
}
exports.ScenarioService = ScenarioService;
function parseScenario(_ref) {
  let {
    areaId,
    areaName,
    computed,
    creationDate,
    scenarioDescription,
    scenarioId
  } = _ref;
  return new _models.Scenario(areaId, areaName, !!computed, (0, _moment.default)(creationDate), scenarioDescription, scenarioId);
}
function parseScenarioDetails(_ref2) {
  let {
    information,
    mapState,
    geojson,
    metaData
  } = _ref2;
  const {
    areaId,
    areaName,
    dataDescription,
    startDate,
    stopDate
  } = information;
  return new _models.ScenarioDetails(areaId, areaName, dataDescription, (0, _moment.default)(startDate), (0, _moment.default)(stopDate), mapState, geojson, metaData);
}