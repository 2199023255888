"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WidgetConfigService = void 0;
var _models = require("../models");
var _app = require("./app");
var _cyclingProfile = require("./cycling-profile");
var _http = require("./http");
class WidgetConfigService {
  static async createWidgetConfig(props) {
    try {
      const data = await _http.HttpService.post('v1', `/partners/${props.partnerId}/widgets`, [], [], JSON.stringify(formatWidgetConfig(props)));
      const config = parseWidgetConfig(data);
      return config;
    } catch (err) {
      console.error('[WidgetConfigService][createWidgetConfig]', err);
      throw err;
    }
  }
  static async getWidgetConfigs(_ref) {
    let {
      partnerId
    } = _ref;
    try {
      const data = await _http.HttpService.get('v1', `/partners/${partnerId}/widgets`);
      const configs = data.map(parseWidgetConfig);
      return configs;
    } catch (err) {
      console.error('[WidgetConfigService][getWidgetConfigs]', err);
      throw err;
    }
  }
  static async getWidgetConfig(id) {
    try {
      const data = await _http.HttpService.get('v1', `/widgets/${id}`);
      const config = parseWidgetConfig(data);
      return config;
    } catch (err) {
      console.error('[WidgetConfigService][getWidgetConfig]', err);
      throw err;
    }
  }
  static async updateWidgetConfig(id, props) {
    try {
      const data = await _http.HttpService.put('v1', `/partners/${props.partnerId}/widgets/${id}`, [], [], JSON.stringify(formatWidgetConfig(props)));
      const config = parseWidgetConfig(data);
      return config;
    } catch (err) {
      console.error('[WidgetConfigService][updateWidgetConfig]', err);
      throw err;
    }
  }
  static async removeWidgetConfig(id, _ref2) {
    let {
      partnerId
    } = _ref2;
    try {
      const succeeded = await _http.HttpService.delete('v1', `/partners/${partnerId}/widgets/${id}`);
      return succeeded;
    } catch (err) {
      console.error('[WidgetConfigService][removeWidgetConfig]', err);
      throw err;
    }
  }
}
exports.WidgetConfigService = WidgetConfigService;
function formatWidgetConfig(_ref3) {
  let {
    partnerId,
    title,
    bounds: {
      north,
      east,
      south,
      west
    },
    defaultCyclingProfile,
    cyclingProfiles,
    enableSimpleSearch: enable_search,
    enableRouting: enable_routing,
    enablePublicTransports: enable_public_transports,
    enableBSS: enable_bike_sharing_system,
    enableRides: enable_rides,
    rideBounds,
    showPartnerRidesOnly,
    showPartnerArea,
    bikeRouteIds: enabled_rideset_ids,
    enableFacilities: show_facilities,
    enableViewer: enable_mapillary,
    enableScrollZoom: enable_scroll_zoom,
    enableSmallDeviceScrollZoom: enable_scroll_zoom_small_device,
    enableTrips: enable_user_trips,
    primaryColor: primary_color,
    secondaryColor: secondary_color
  } = _ref3;
  return {
    partner: partnerId,
    title,
    bounds_east: east,
    bounds_west: west,
    bounds_north: north,
    bounds_south: south,
    default_cycling_profile: _cyclingProfile.cyclingProfilesMap[defaultCyclingProfile],
    enabled_cycling_profiles: cyclingProfiles.map(key => _cyclingProfile.cyclingProfilesMap[key]),
    enable_search,
    enable_routing,
    enable_public_transports,
    enable_bike_sharing_system,
    enable_rides,
    show_partner_rides_only: showPartnerRidesOnly,
    show_partner_area: showPartnerArea,
    bounds_rides_east: enable_rides && rideBounds?.east || null,
    bounds_rides_west: enable_rides && rideBounds?.west || null,
    bounds_rides_north: enable_rides && rideBounds?.north || null,
    bounds_rides_south: enable_rides && rideBounds?.south || null,
    enable_ridesets: enabled_rideset_ids.length > 0,
    enabled_rideset_ids,
    show_facilities,
    enable_mapillary,
    enable_scroll_zoom,
    enable_scroll_zoom_small_device,
    enable_user_trips,
    primary_color,
    secondary_color
  };
}
function parseWidgetConfig(_ref4) {
  let {
    id,
    partner: partnerId,
    api_key: apiKey,
    code,
    title,
    bounds_east: east,
    bounds_west: west,
    bounds_north: north,
    bounds_south: south,
    default_cycling_profile: defaultCyclingProfile,
    enabled_cycling_profiles: cyclingProfiles,
    enable_search: enableSimpleSearch,
    enable_routing: enableRouting,
    enable_public_transports: enablePublicTransports,
    enable_bike_sharing_system: enableBSS,
    enable_rides: enableRides,
    show_partner_rides_only: showPartnerRidesOnly,
    show_partner_area: showPartnerArea,
    bounds_rides_east: ridesEast,
    bounds_rides_west: ridesWest,
    bounds_rides_north: ridesNorth,
    bounds_rides_south: ridesSouth,
    enable_ridesets: enableBikeRoutes,
    enabled_rideset_ids: bikeRouteIds,
    show_facilities: enableFacilities,
    enable_scroll_zoom: enableScrollZoom,
    enable_scroll_zoom_small_device: enableSmallDeviceScrollZoom,
    enable_user_trips: enableTrips,
    enable_mapillary: enableViewer,
    primary_color: primaryColor,
    secondary_color: secondaryColor,
    partner_area: area
  } = _ref4;
  return new _models.WidgetConfig({
    id,
    partnerId,
    apiKey: apiKey || _app.AppService.environment.widgetDefaultApiKey,
    code,
    title,
    bounds: north && east && south && west && {
      north,
      east,
      south,
      west
    } || undefined,
    enableSimpleSearch,
    enableRouting,
    cyclingProfiles: (cyclingProfiles || []).map(key => _cyclingProfile.backendCyclingProfiles[key]),
    defaultCyclingProfile: defaultCyclingProfile ? _cyclingProfile.backendCyclingProfiles[defaultCyclingProfile] : 'touristic',
    enablePublicTransports,
    enableBSS,
    enableRides,
    showPartnerRidesOnly,
    showPartnerArea,
    rideBounds: ridesNorth && ridesEast && ridesSouth && ridesWest && {
      north: ridesNorth,
      east: ridesEast,
      south: ridesSouth,
      west: ridesWest
    } || undefined,
    bikeRouteIds: enableBikeRoutes && bikeRouteIds || [],
    enableTrips,
    enableFacilities,
    enableViewer,
    disableScrollZoom: !enableScrollZoom,
    disableSmallDeviceScrollZoom: !enableSmallDeviceScrollZoom,
    primaryColor: primaryColor || '#326ac2',
    secondaryColor: secondaryColor || '#2ac682',
    area: area || undefined
  });
}