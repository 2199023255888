"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParkingService = void 0;
var _models = require("../models");
var _http = require("./http");
class ParkingService {
  static async getParkingLots(_ref) {
    let {
      cyclabilityZoneId,
      zoom,
      bounds,
      forceUpdate
    } = _ref;
    if (zoom < 16 && !forceUpdate) return [];
    try {
      const params = [];
      if (cyclabilityZoneId) params.push({
        key: 'cyclability_zone_id',
        value: cyclabilityZoneId
      });
      if (bounds) params.push({
        key: 'north',
        value: bounds.north
      }, {
        key: 'east',
        value: bounds.east
      }, {
        key: 'south',
        value: bounds.south
      }, {
        key: 'west',
        value: bounds.west
      });
      const data = await _http.HttpService.get('v1', '/parkings', params);
      return data.map(parseParking);
    } catch (err) {
      console.error('[ParkingService][getParkingLots]', err);
      throw err;
    }
  }
}
exports.ParkingService = ParkingService;
const rackTypes = {
  ANCHORS: 'anchors',
  BUILDING: 'building',
  LOCKERS: 'lockers',
  OTHER: 'other',
  SHED: 'shed',
  STANDS: 'stands',
  UNKNOWN: 'unknown',
  WALL_LOOPS: 'wallLoops'
};
const accessTypes = {
  CUSTOMERS: 'customers',
  PRIVATE: 'private',
  PUBLIC: 'public'
};
const networks = {
  BiciBox: 'bicibox',
  Veligo: 'veligo',
  Velocity: 'velocity',
  Véligo: 'veligo',
  Vélocéo: 'veloceo'
};
function parseParking(_ref2) {
  let {
    id,
    code,
    location,
    capacity,
    type,
    access_type,
    is_payant,
    isSheltered,
    is_station,
    is_supervised,
    is_locked,
    subscription,
    network,
    website
  } = _ref2;
  return new _models.Parking(id, code, new _models.Place(undefined, location), capacity, rackTypes[type], accessTypes[access_type], !is_payant, isSheltered, is_station, is_supervised, is_locked, subscription, network && networks[network] || null, website);
}